[class^=icon-]{display: inline-block;background-size: contain;background-repeat: no-repeat;background-position: 0 0;font-style: normal;}
.icon-close-white{background-image: url('../images/x-white.svg');width: 35px;height: 34px;}
.icon-down-white{background-image: url('../images/chevron-down-solid.svg');width: 15px;height: 15px;}
.icon-search{background-image: url('../images/icon-search.svg');width: 30px;height: 30px;vertical-align: middle;}
.icon-search-white{background-image: url('../images/icon-search-white.svg');width: 24px;height: 24px;vertical-align: middle;}
.icon-search-big{background-image: url('../images/icon-search-big.svg');width: 39px;height: 39px;vertical-align: middle;}
.icon-hamburger{background-image: url('../images/icon-hamburger.png');width: 54px;height: 54px;vertical-align: middle;}
.icon-window{background-image: url('../images/icon-window.svg');width: 13px;height: 13px;}
.icon-share{background-image: url('../images/icon-share.svg');width: 18px;height: 18px;}
.icon-download{background-image: url('../images/icon-download.svg');width: 16px;height: 18px;}
.icon-outlink{background-image: url('../images/icon-outlink.svg');width: 10px;height: 10px;}
.icon-x{background-image: url('../images/icon-x.svg');width: 26px;height: 26px;}
.icon-del{background-image: url('../images/icon-del.svg');width: 12px;height: 12px;}
.icon-del-bold{background-image: url('../images/icon-del-bold.png');width: 9px;height: 9px;}
.icon-top{background-image: url('../images/icon-top.svg');width: 27px;height: 33px;}
.icon-heart{background-image: url('../images/icon-heart.svg');width: 18px;height: 17px;}
.icon-home{background-image: url('../images/icon-home.png');width: 25.5px;height: 24.5px;}
.icon-warning{background-image: url('../images/icon-warning.svg');width: 66px;height: 58px;}
.icon-info{background-image: url('../images/icon-info.svg');width: 28px;height: 28px;}

.icon-arrow-right{display: inline-block;width: 9px;height: 9px;border-top: 2px solid #000;border-right: 2px solid #000;transform: rotate(45deg);border-radius: 1px;vertical-align: middle;}
.icon-arrow-down{display: inline-block;width: 9px;height: 9px;border-bottom: 2px solid #666;border-right: 2px solid #666;transform: rotate(45deg);border-radius: 1px;vertical-align: middle;}

.icon-link{background-image: url('../images/icon-link.svg');width: 20px;height: 21px;background-position: center;}
.icon-instagram{background-image: url('../images/icon-instagram.svg');width: 24px;height: 22px;background-position: center;}
.icon-facebook{background-image: url('../images/icon-facebook.svg');width: 27px;height: 28px;background-position: center;}
.icon-facebook-slim{background-image: url('../images/icon-facebook-slim.svg');width: 12px;height: 22px;background-position: center;}
.icon-twitter{background-image: url('../images/icon-twitter.svg');width: 22px;height: 19px;background-position: center;}
.icon-youtube{background-image: url('../images/icon-youtube.svg');width:30px;height: 22px;background-position: center;}
.icon-navershop{background-image: url('../images/icon-navershop.svg');width: 23px;height: 23px;background-position: center;}
.icon-kakao{background-image: url('../images/icon-kakao.svg');width: 24px;height: 22px;background-position: center;}
.icon-instagram-dark{background-image: url('../images/icon-instagram-dark.svg');width: 20px;height: 20px;background-position: center;}
.icon-facebook-dark{background-image: url('../images/icon-facebook-dark.svg');width: 22px;height: 22px;background-position: center;}
.icon-youtube-dark{background-image: url('../images/icon-youtube-dark.svg');width:24px;height: 18px;background-position: center;}
.icon-navershop-dark{background-image: url('../images/icon-navershop-dark.svg');width: 20px;height: 20px;background-position: center;}

.icon-new{width: auto;height: auto;background: transparent;color: $danger;font-size: 12px;font-weight: 700;font-family: 'Segoe UI';transform: translate(-2px, -4px);}
.icon-new-badge{width: 18px;height: 18px;background: $danger;color: #fff;font-size: 12px;font-weight: 700;font-family: 'Segoe UI';transform: translate(0, -8px);text-align: center;line-height: 17px;vertical-align: middle;}
@include media-breakpoint-down(md) {
    .icon-new-badge{
        width: 15px;height: 15px;font-size: 10px;line-height: 14px;
    }
}

.segoeUI{font-family: 'segoe UI';}

.min-width-auto{min-width: auto !important;}

[contenteditable=true]:empty:before{
    content: attr(placeholder);color: #bbb;cursor: text;
    display: inline-block;
}

.select-design{
    position: relative;
    .selected{
        display: block;background: #000;position: relative;height: 60px;color: #fff;font-size: 18px;line-height: 60px;text-overflow:ellipsis;overflow: hidden;white-space:nowrap;padding: 0 40px 0 20px;text-decoration: none;
        &::before{
            content: '';border-color: #fff transparent transparent transparent;border-style: solid;border-width: 9px 7.5px 0 7.5px;height: 0;margin-top: -4.5px;position: absolute;top: 50%;right: 19px;width: 0;
        }
    }
    .scroll-y-light{max-height: 550px;
        .mCSB_inside>.mCSB_container{margin-right: 0;}
        .mCSB_scrollTools{opacity: 1;top: 15px;bottom: 15px;width: 20px;}
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{background: #fff;border-radius: 0;opacity: 1;width: 5px;}
        .mCSB_scrollTools .mCSB_draggerRail{width: 5px;background: #333;}
    }
    .list{
        position: absolute;left: 0;top: 61px;right: 0;z-index: 1;display: none;background: #141414;
        ul{box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.2);}
        li + li{border-top: 1px solid rgba(255,255,255,.05);}
        a{color: #fff;font-size: 18px;background: #141414;display: block;padding: 18px;padding: 18px 20px;line-height: 1.35;text-decoration: none;
            &:hover{background: #000;}
            &.highlight{text-decoration: underline;background: #000;}
        }
    }
    &.active{
        .selected{
            &::before{transform: rotate(180deg);}
        }
        .list{display: block;}
    }
}
@include media-breakpoint-down(md) {
    .select-design{
        .selected{
            height: 50px;line-height: 50px;font-size: 14px;
            &::before{border-width: 6px 5px 0 5px;height: 0;margin-top: -2.5px;right: 14px;}
        }
        .scroll-y-light{max-height: 300px;}
        .list{
            top: 51px;max-height: 300px;overflow: hidden;
            a{padding: 15px;font-size: 14px;}
        }
    }
}

@include media-breakpoint-down(md) {
    .badge{font-size: 12px;}
}

.btn{
    min-width: 200px;
    i{transform: translateY(3px);}
}
.btn-lg{font-weight: 700;}
@include media-breakpoint-down(md) {
    .btn-lg{min-width: 0;font-size: 14px;line-height: 1.2;}
}

.btn-with-icon{
    display: inline-flex;justify-content: space-between;padding-left: 30px;padding-right: 20px;
}

.btn-outline-dark:hover{
    .icon-download{background-image: url('../images/icon-download-active.svg');}
}

.tags{margin: -5px;display: flex;flex-wrap: wrap;}
.tag{
    border-radius: 10rem;height: 30px;color: #999;padding: 4px 15px;background: #f6f6f6;margin: 5px;transition: .15s;
    &.active{background: #333;color: #fff;}
}
button.tag:hover{background: #333;color: #fff;}
@include media-breakpoint-down(md) {
    .tag{height: 25px;padding: 3px 10px;font-size: 12px;}
}

.title-area{
    margin-bottom: 45px;
    .title{font-size: 80px;letter-spacing: -2px;font-weight: 700;line-height: 1;}
}
.subtitle{font-size: 26px;font-weight: 700;letter-spacing: -0.65px;line-height: 1;color: #000;border-bottom: 2px solid #000;padding-bottom: 20px;margin-bottom: 25px;margin-top: 100px;}
@include media-breakpoint-down(md) {
    .title-area{
        margin-bottom: 38px;
        .title{font-size: 40px;}
    }
    .subtitle{font-size: 19px;letter-spacing: -0.95px;padding-bottom: 10px;margin-top: 75px;margin-bottom: px;}
}

.info-row{margin-top: 45px;}
.info-row + .info-row{border-top: 1px solid #ddd;margin-top: 45px;padding-top: 50px;}
.info-title{font-size: 22px;font-weight: 700;letter-spacing: -0.55px;line-height: 1;margin-bottom: 15px;}
.info-text{font-size: 18px;color: #666;letter-spacing: -0.45px;line-height: 1.67;
    & + .table{margin-top: 30px;}
    & + .table-responsive{margin-top: 30px;}
}
.noti{
    > li{
        position: relative;padding-left: 16px;font-size: 18px;line-height: 1.67;color: #666;word-break: keep-all;
        &::before{content: '';display: block;width: 5px;height: 5px;background: #666;border-radius: 50%;position: absolute;left: 0  ;top: 10px;}
    }
}
@include media-breakpoint-down(md) {
    .info-row + .info-row{margin-top: 20px;padding-top: 25px;}
    .info-title{font-size: 16px;margin-bottom: 10px;}
    .info-text{font-size: 14px;
        & + .table{margin-top: 20px;}
        & + .table-responsive{margin-top: 20px;}
    }
    .noti{
        > li{
            padding-left: 10px;font-size: 14px;
            &::before{width: 4px;height: 4px;top: 7px;}
        }
    }
}

.info-ul{
    margin-top: 20px;
    > li{font-size: 18px;font-weight: 700;color: #666;line-height: 1.67;letter-spacing: -0.45px;}
    .info-ul-sm{margin-left: 18px}
}
.info-ul-sm{
    margin-top: 5px;
    > li{
        font-size: 16px;color: #666;line-height: 1.63;letter-spacing:  -0.45px;position: relative;padding-left: 15px;font-weight: 400;
        &::before{content: '-';position: absolute;left: 0;top: 0;}
    }
    & + .table-responsive{margin-top: 30px;}
}
@include media-breakpoint-down(md) {
    .info-ul{
        > li{font-size: 14px;}
        .info-ul-sm{margin-left: 0;}
    }
    .info-ul-sm{
        margin-left: 0;
        > li{font-size: 13px;}
    }
}

.bottom-btn{margin-top: 100px;text-align: center;
    .btn{min-width: 200px;}
}
@include media-breakpoint-down(md) {
    .bottom-btn{margin-top: 35px;
        .btn{width: 100%;}
    }
}

.tab-area{margin-bottom: 40px;}
.tab{
    .nav-pills{
        .nav-link{font-size: 22px;line-height: 1.55;}
    }
    .nav-item + .nav-item{margin-left: 10px;}
    .swiper-slide{width: auto !important;height: auto !important;}
    &.swiper-container-initialized{
        .nav-pills{
            flex-wrap: nowrap;
        }
    }
}
@include media-breakpoint-down(xl) {
    .tab{
        .nav-pills{
            .nav-link{font-size: 20px;}
        }
    }
}
@include media-breakpoint-down(lg) {
    .tab{
        .nav-pills{
            .nav-link{font-size: 18px;}
        }
    }
}
@include media-breakpoint-down(md) {
    .tab-area{margin-bottom: 25px;}
    .tab{
        .nav-pills{
            .nav-link{font-size: 15px;padding-left: 20px;padding-right: 20px;line-height: 1.3;}
        }
        .nav-item + .nav-item{margin-left: 0;}
        &.swiper-container-initialized{
            margin: 0 -20px;
            .nav-item + .nav-item{margin-left: 0;}
        }
    }
}

.long{
    height: 100px;margin-top: 100px;position: relative;display: none;
    .container{height: 100%;position: relative;}
    a{
        display: flex;justify-content: center;flex-direction: column;width: 100%;height: 100%;color: #fff;background-size: cover;background-repeat: no-repeat;background-position: 50% 50%;padding: 0 90px;
        span{font-weight: 500;}
        strong{font-weight: 700;font-size: 20px;margin-top: 6px;}
    }
    .close{
        position: absolute;right: 15px;top: 0;opacity: 1;background: #000;width: 25px;height: 25px;text-align: center;font-size: 9px;
        .icon-close-white{width: 12px;height: 12px;vertical-align: middle;}
    }
}
@include media-breakpoint-down(md) {
    .long{
        height: 75px;margin-top: 40px;
        a{
            padding: 0 20px;
            span{font-size: 13px;}
            strong{font-size: 14px;}
        }
        .close{right: 30px;}
    }
}

.player video{vertical-align: top;width: 100%;height: 100%;}
.player video:focus{outline: 0;}
.player button{position: absolute;left: -9999px;width: 0;height: 0;opacity: 0;font-size: 0;line-height: 0;}

.video-frame{
    width: 100%;padding-bottom: 56.25%;height: 0;position: relative;
    video,
    iframe{position: absolute;left: 0;top: 0;width: 100%;height: 100%;}
}

.social-list{
    display: inline-flex;
    a{
        display: flex;width: 40px;height: 40px;border-radius: 50%;background: #000;align-items: center;justify-content: center;padding: 10px;
        i{vertical-align: middle;}
        & + a{margin-left: 10px;}
    }
}
@include media-breakpoint-down(md) {
    .social-list{
        a{
            width: 30px;height: 30px;padding: 3px;
            & + a{margin-left: 6px;}
            i{max-height: 14px !important}
        }
    }
}

@include media-breakpoint-down(md) {
    .pagination{margin-top: 50px;}
    .page-link{width: 30px;height: 30px;}
}


.modal{
    .modal-content{
        > .close{position: absolute;right: 30px;top: 30px;z-index: 1;}
    }
}
@include media-breakpoint-down(md) {
    .modal{
        .modal-content{
            > .close{position: absolute;right: 15px;top: 15px;z-index: 1;}
        }
    }
}

.share{
    position: absolute;right: 15px;top: 50%;transform: translateY(-50%);font-size: 0;width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;cursor:pointer;
    .btn-sns{vertical-align: top;}
    i{vertical-align: top;}
    .list{position: absolute;right: 100%;top: 50%;transform: translateY(-50%);z-index: 1;opacity: 0;visibility: hidden;transition: opacity .2s;
        ul{
            background: #000; box-shadow: 0px 15px 15px 0 rgba(0, 0, 0, 0.1);display: flex;padding: 0 2px;position: relative;
            &::before{content: '';display: block;width: 0;height: 0;border-style: solid;border-width: 5.5px 0 5.5px 5px;border-color: transparent transparent transparent #000000;position: absolute;right: -5px;top: 50%;margin-top: -5.5px;}
        }
        li{padding: 0 2px;position: relative;}
        li + li{
            &::before{content: '';width: 1px;height: 20px;background: rgba(255, 255, 255, 0.2);display: block;position: absolute;left: 0;top: 50%;margin-top: -10px;}
        }
        a{display: flex;height: 100%;align-items: center;justify-content: center;padding: 13px;}
    }
    &:focus,
    &:hover{
        .list{visibility: visible;opacity: 1;}
    }
}