.body-main{
    padding-top: 0;
    #header{background: transparent;/* transition-delay: .6s; */}
    #content{padding-top: 0;padding-left: 0;padding-right: 0;}
}

section{
    @include media-breakpoint-down(xxxl) {
        .swiper-container-horizontal>.swiper-scrollbar{left: 272px;}
    }
    @include media-breakpoint-down(xxl) {
        .swiper-container-horizontal>.swiper-scrollbar{left: 238px;}
    }
    @include media-breakpoint-down(xl) {
        .swiper-container-horizontal>.swiper-scrollbar{left: 224px;}
    }
    @include media-breakpoint-down(lg) {
        .swiper-container-horizontal>.swiper-scrollbar{left: 204px;}
    }
    @include media-breakpoint-down(md) {
        .swiper-container-horizontal>.swiper-scrollbar{left: 60px;height: 4px;}
    }
}

@include media-breakpoint-down(md) {
    .mouse-scroll{display: none;}
}
@-webkit-keyframes scroll {
    0% {opacity: 1;}
    100% {
        opacity: 0.4;
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
@keyframes scroll {
    0% {opacity: 1;}
    100% {
        opacity: 0.4;
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
.main-top-promotion{
    width: 100%;height: calc(100vh - 100px);overflow: hidden;transition: transform .5s ease-in-out;position: relative;
    .video-promotion{
        width: 100%;height: 100%;background: #000;
        .control-mask{height: 100%;position: absolute;top: 0;bottom: 0;width: 100%;z-index: 2;}
        .video{
            height: 100%;position: relative;width: 100%;
            iframe,
            video{width: 100% !important;height: 100% !important;vertical-align: top;background-color: #000;background-size: cover;}
        }
    }
    .image-promotion{
        height: 100%;
        .swiper-container{height: 100%;}
        .swiper-wrapper{transition-timing-function: ease-out;}
        .swiper-slide{
            img{width: 100%;height: 100%;object-fit: cover;}
        }
        .swiper-pagination{bottom: 120px;}
        .swiper-button-next, .swiper-button-prev{margin-top: -45px;}
        .swiper-button-prev{width: 90px;height: 90px;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='92' height='92'%3E%3Cpath fill='%23FFF' fill-opacity='0' fill-rule='evenodd' d='M0 0h90v90H0V0z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23111' stroke-width='4' d='M53.609 26.413L35.811 44.499l17.798 18.088'/%3E%3C/svg%3E%0A");background-size: contain;left: 29px;}
        .swiper-button-next{width: 90px;height: 90px;background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='92' height='92'%3E%3Cpath fill='%23FFF' fill-opacity='0' fill-rule='evenodd' d='M0 0h90v90H0V0z'/%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23111' stroke-width='4' d='M36 26l18.001 17.999L36 62'/%3E%3C/svg%3E%0A");background-size: contain;right: 29px;}
        & + .btn-details{bottom: 173px;}
    }
    .btn-details{
        position: absolute;left: 50%;bottom: 132px;transform: translateX(-50%);z-index: 3;
        .btn{min-width: 180px;padding-top: .75rem;padding-bottom: .75rem;line-height: 1.35;}
    }
    .mouse-scroll {
        display: table;margin: auto;position: absolute;left: 50%;bottom:31px;transform: translateX(-50%);z-index: 2;cursor: pointer;
        .mouse-body {
            width: 28px;height: 50px;padding-top: 8px;border: 1px solid rgba(255,255,255,.6);border-radius: 15px;text-align: center;
            .mouse-wheel {
                margin: auto;width: 2px;height: 10px;border-radius: 50%;background-color: rgba(255,255,255,.9);-webkit-animation: scroll 1.4s infinite;-moz-animation: scroll 1.4s infinite;animation: scroll 1.4s infinite;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    #top-block{height: calc(100% - 50px);}
    .main-top-promotion{
        height: 100%;background: #000;
        .video-promotion{
            background: #000;height: 100%;display: flex;align-items: center;justify-content: center;
            .control-mask{bottom: 50px;}
            .video{
                height: 0;position: relative;padding-bottom: 56.25%;
                iframe,
                video{position: absolute;left: 0;top: 0;}
            }
        }
        .image-promotion{
            .swiper-pagination{bottom: 153px;}
            .swiper-button-next, .swiper-button-prev{width: 50px;height: 50px;margin-top: -25px;}
            .swiper-button-prev{left: 0;}
            .swiper-button-next{right: 0;}
            .btn-details{
                bottom: 88px;
                .btn{min-width: 140px;line-height: 1;}
            }
        }
        .btn-details{
            .btn{min-width: 140px;height: 40px;padding-top: 11px;font-size: 14px;}
        }
    }
}
#top-block{
    & ~ #wrap{
        #header{position: absolute;
            .header-wrap{margin-top: 0;}
        }
    }
}
html:not(.is-scrolled){
    #top-block ~ #wrap{
        #header{background: #fff;}
        #header .gnb .dep2{opacity: 0;visibility: hidden;}
    }
}

.go-video,
.indicator{opacity: 0;visibility: hidden;transition: .4s ease-in .1s;}
html.is-start{
    .indicator{opacity: 1;visibility: visible;}
}

html.is-scrolled.header-fixed{
    .go-video,
    .indicator{opacity: 1;visibility: visible;}
    @include media-breakpoint-up(lg) {
        .indicator{bottom: 190px;}
    }
}
html.is-scrolled.is-bottom{
    .go-video{bottom: 680px;}
    .indicator{bottom: 430px;}
}
@include media-breakpoint-down(md) {
    html.is-scrolled.is-bottom{
        .go-video{bottom: 328px;}
        .indicator{bottom: 440px;}
    }
}
.go-video{
    box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.15);position: fixed;left: 64px;bottom: 480px;z-index: 10;border-radius: 50%;transition: .4s ease-in .1s;
    img{width: 80px;height: 80px;object-fit: cover;border-radius: 50%;}
    &::before{content: '';display: block;background-image: url('../images/icon-go-video.png');background-size: contain;width: 30px;height: 30px;position: absolute;right: -1px;top: -3px;}
}
@include media-breakpoint-down(xxxl) {
    .go-video{left: 34px;}
}
@include media-breakpoint-down(md) {
    .go-video{top: auto;bottom: 10px;left: 10px;
        img{width: 40px;height: 40px;}
        &::before{width: 15px;height: 15px;}
    }
}

.indicator{
    position: fixed;left: 90px;bottom: 40px;z-index: 10;
    .list-group-item{
        display: block;width: 30px;height: 30px;padding: 0;border: 1px solid transparent;border-radius: 50%;position: relative;background: none !important;margin: 6px 0;transition: .15s;
        &::before{content: '';display: block;width: 5px;height: 5px;background: #000;border-radius: 50%;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
        span{position: absolute;left: calc(100% + 5px);top: 0;border-radius: 15px;color: #333;height: 100%;padding: 0 1rem;white-space: nowrap;font-size: 14px;letter-spacing: -0.35px;padding-top: 4px;box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);background-color: rgba(255,255,255,0.5);display: none;}
        &:hover,
        &.active{
            border-color: #000;
        }
        &:hover{
            span{display: block;}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    .indicator{left: 59px;}
}
@include media-breakpoint-down(md) {
    .indicator{
        left: 11px;bottom: 50%;transform: translateY(50%);
        .list-group-item{
            width: 9px;height: 9px;
            &::before{width: 3px;height: 3px;}
            span{display: none !important;}
            &.active{
                &::before{display: none;}
            }
        }
    }
}

section.main-section{
    .container{max-width: none;padding: 0;}
    .container > .d-lg-flex{margin-bottom: 48px;}
    .section-title{font-size: 38px;font-family: 'Segoe UI', 'NanumBarunGothic';font-weight: 700;margin-left: 322px;}
    .more{font-weight: 400;font-family: 'Segoe UI';margin-right: 62px;
        &::after{content: '';display: inline-block;width: 5px;height: 10px;background: url('../images/icon-view-more.svg') no-repeat 50% 50%;margin: 0 0 0 10px;transform: translateY(1px);}
    }
    a:hover{text-decoration: none;}
}
@include media-breakpoint-down(xxxl) {
    section .section-title{margin-left: 248px;}
}
@include media-breakpoint-down(xxl) {
    section .section-title{margin-left: 238px;}
}
@include media-breakpoint-down(xl) {
    section .section-title{margin-left: 223px;font-size: 32px;}
}
@include media-breakpoint-down(lg) {
    section .section-title{margin-left: 189px;}
}
@include media-breakpoint-down(md) {
    section.main-section{
        .container > .d-lg-flex{margin-bottom: 35px;}
        .section-title{font-size: 30px;margin-left: 60px;}
        .more{display: inline-block;margin: 15px 0 0 60px;}
    }
}
section.visual{
    height: 100vh;padding-left: 220px;
    .item{
        position: relative;width: 100%;height: 100%;margin-top: -100px;height: calc(100% + 100px);
        > a{display: block;height: 100%;}
    }
    .img{position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-repeat: no-repeat;background-size: cover;background-position: 50% 50%;transition: 6s linear;}
    .text{position: absolute;left: 102px;top: 50%;z-index: 1;transform: translateY(-50%);margin-top: -50px;}
    .tit{display: block;font-size: 80px;font-weight: 600;line-height: 1.23;margin-bottom: 20px;transform: translateY(50px);opacity: 0;transition: .9s ease .3s;}
    .desc{font-size: 28px;line-height: 1.64;letter-spacing: -0.7px;margin-top: 50px;transform: translateY(50px);opacity: 0;transition: .9s ease .6s;}
    .btns{
        margin-top: 50px;transform: translateY(50px);opacity: 0;transition: .9s ease .9s;
        .btn-details{min-width: 180px;padding: 12px 0;height: 50px;}
    }
    .product{position: absolute;top: 50%;right: 140px;transform: translateY(-50%);}
    .swiper-container{height: 100%;}
    .swiper-slide{
        height: 100%;background-repeat: no-repeat;background-position: 50% 100%;background-size: cover;padding-top: 100px;
    }
    .swiper-pagination{
        font-family: 'Segoe UI';font-size: 14px;font-weight: 700;position: static;width: auto;left: auto;
        .line{display: inline-block;vertical-align: middle;width: 70px;height: 1px;background: rgba(130, 130, 130, 0.3);margin: 0 5px;transform: translateY(-1px);}
    }
    .swiper-button-wrap{
        position: static;display: flex;align-items: center;margin-left: 2rem;
        > *{position: static;margin: 0 10px;}
        .swiper-button-prev{background: url('../images/visual-prev.svg') no-repeat 50% 50%;width: 14px;height: 20px;}
        .swiper-button-next{background: url('../images/visual-next.svg') no-repeat 50% 50%;width: 14px;height: 20px;}
    }
    .swiper-controller-group{
        position: absolute;right: 45px;bottom: 50px;z-index: 1;white-space: nowrap;display: flex;padding: 10px 5px 10px 20px;transition: background .2s;border-radius: 50rem;
        @include media-breakpoint-up(lg){
            &:hover{
                background: rgba(255,255,255,0.6);
            }
        }
    }
    .swiper-slide-active{
        .img{transform: scale(1.1);}
        .tit{transform: translateY(0);opacity: 1;}
        .desc{transform: translateY(0);opacity: 1;}
        .btns{transform: translateY(0);opacity: 1;}
    }
}
@include media-breakpoint-down(xxxl) {
    section.visual{
        padding-left: 172px;
        .text{left: 76px;}
        .tit{font-size: 70px;}
        .desc{font-size: 24px;line-height: 1.58;}
        .product{
            right: 62px;
            img{max-width: 592px;}
        }
    }
}
@include media-breakpoint-down(xxl) {
    section.visual{
        padding-left: 166px;
        .text{left: 72px;}
        .tit{font-size: 62px;}
        .product{
            img{max-width: 525px;}
        }
    }
}
@include media-breakpoint-down(xl) {
    section.visual{
        padding-left: 158px;
        .text{left: 66px;}
        .tit{font-size: 60px;}
        .desc{font-size: 20px;}
        .btns{margin-top: 35px;}
        .product{
            img{max-width: 444px;}
        }
    }
}
@include media-breakpoint-down(lg) {
    section.visual{
        padding-left: 138px;
        .text{left: 52px;}
        .btns{margin-top: 35px;}
        .product{
            img{max-width: 405px;}
        }
    }
}
@include media-breakpoint-down(md) {
    section.visual{
        padding-left: 30px;
        .text{left: 30px;top: 70%;}
        .tit{font-size: 35px;margin-bottom: 12px;}
        .desc{font-size: 16px;margin-top: 20px;}
        .btns{
            margin-top: 20px;
            .btn-details{height: 40px;font-size: 14px;min-width: 145px;padding: 10px 0;}
        }
        .product{transform: none;top: 30px;transform: translateX(5%);right: 0;
            img{max-width: 90vw;}
        }
        .swiper-pagination{
            font-size: 12px;color: #000;
            .line{width: 20px;}
        }
        .swiper-button-wrap{display: none;}
        .swiper-controller-group{
            right: 20px;
        }
    }
}

section.popular{
    padding: 200px 0;
    .popular-list{
        ul{
            display: flex;
        }
        .img{
            width: 100%;height: 0;padding-bottom: 100%;overflow: hidden;background: #f0f0f0;position: relative;
            img{position:absolute;left:50%;top: 50%;transform: translate(-50%, -50%);
                -webkit-transition: opacity .3s ease-in-out;
                -moz-transition: opacity .3s ease-in-out;
                -o-transition: opacity .3s ease-in-out;
                transition: opacity .3s ease-in-out;}
            .bottom{z-index: 0;opacity: 0;vertical-align: top;width: 100%;height: 100%;object-fit: cover;}
        }
        a:hover{
            .img{
                .top{opacity: 0;}
                .bottom{opacity: 1;}
            }
        }
        .text{
            margin-top: 25px;
            .tit{font-size: 18px;font-weight: 700;letter-spacing: -0.45px;margin-bottom: 5px;}
            .model{font-size: 14px;font-weight: 700;font-family: 'Segoe UI';color: $danger;margin-bottom: 6px;}
            .desc{color: #999;line-height: 1.5;}
        }
        .swiper-container{padding-bottom: 60px;}
        .swiper-slide{width: 340px !important;}
        .swiper-wrapper:hover{
            & ~ .swiper-scrollbar{opacity: 1 !important;}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    section.popular .popular-list .swiper-slide{width: 254px !important;}
}
@include media-breakpoint-down(xxl) {
    section.popular .popular-list .swiper-slide{width: 240px !important;}
}
@include media-breakpoint-down(xl) {
    section.popular .popular-list .swiper-slide{width: 224px !important;}
    section.popular .popular-list .text .tit{font-size: 16px;}
    section.popular .popular-list .text .desc{font-size: 14px;}
    section.popular .popular-list .img .top {max-width: 80%;}
}
@include media-breakpoint-down(lg) {
}
@include media-breakpoint-down(md) {
    section.popular{
        padding: 100px 0;
        .popular-list{
            .img{height: 180px;}
            .text{
                padding-left: 0;
                .tit{font-size: 15px;}
                .desc{font-size: 13px;}
            }
            .swiper-container{padding-bottom: 40px;}
            .swiper-slide{width: 180px !important;}
        }
    }
}

section.promotion{
    padding: 150px 0;background-color: #2945aa;position: relative;
    h2{font-size: 94px;font-weight: 300;font-family: 'Segoe UI','NanumBarunGothic';line-height: 1;color: #fff;margin-left: 322px;}
    .more{
        color: #fff;position: relative;
        &::after{display: none;}
        .icon-arrow-right{
            width: 7px;height: 7px;border-top: 2px solid #000;border-top: 2px solid #000;
        }
    }
    .bg{position: absolute;left: 0;top: 0;width: 100%;height: 100%;background-repeat: no-repeat;background-size: cover;background-position: 50% 50%;}
    > .conatiner{position: relative;}
    .promotion-list{
        padding-left: 220px;overflow: hidden;
        .wrap{margin: 0 -12px;display: flex;}
        .left{flex: 0 0 56.5882353%;max-width: 56.5882353%;padding: 0 12px;}
        .right{
            flex: 0 0 43.4117647%;max-width: 43.4117647%;padding: 0 12px;
            .inner{width: 100%;height: 100%;overflow: hidden;}
            .d-flex{height: calc(50% - 12px);margin: 0 -12px;}
            .d-flex:nth-child(1){margin-bottom: 24px;}
        }
        .column-lg{flex: 0 0 56.368563686%;max-width: 56.368563686%;padding: 0 12px;}
        .column-sm{flex: 0 0 43.631436314%;max-width: 43.631436314%;padding: 0 12px;}
        .item{width: 100%;height: 100%;display: block;position: relative;}
        .img{
            display: block;overflow: hidden;width: 100%;height: 100%;position: relative;
            img{width: 100%;height: 100%;object-fit: cover;vertical-align: top;}
        }
        .layer{
            position: absolute;left: 0;top: 0;right: 0;bottom: 0;position: absolute;visibility: hidden;opacity: 0;transition: opacity .2s;
            .text{
                background: rgba(0,0,0,0.7);color: #fff;
                position: absolute;left: 0;bottom: 0;right: 0;padding: 20px;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;text-align: center;
                .tit{font-size: 30px;font-weight: 700;color: #fff;margin-bottom: 10px;max-height: 3em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
                .desc{font-size: 18px;line-height: 1.56;letter-spacing: -0.45px;max-height: 3.12em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
            }
        }
        .item:hover{
            .layer{visibility: visible;opacity: 1;}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    section.promotion h2{margin-left: 247px;}
    section.promotion .promotion-list{padding-left: 172px;}
}
@include media-breakpoint-down(xxl) {
    section.promotion{padding: 140px 0;}
    section.promotion h2{margin-left: 238px;}
    section.promotion .promotion-list{padding-left: 166px;}
    section.promotion .promotion-list .layer .text .tit{font-size: 26px;}
    section.promotion .promotion-list .layer .text .desc{font-size: 16px;}
}
@include media-breakpoint-down(xl) {
    section.promotion{padding: 100px 0;}
    section.promotion h2{margin-left: 223px;font-size: 70px;}
    section.promotion .promotion-list{padding-left: 158px;}
    section.promotion .promotion-list .layer .text{padding: 10px;}
    section.promotion .promotion-list .layer .text .tit{font-size: 20px;}
    section.promotion .promotion-list .layer .text .desc{font-size: 14px;}
}
@include media-breakpoint-down(lg) {
    section.promotion h2{margin-left: 189px;}
    section.promotion .promotion-list{padding-left: 138px;}
    section.promotion .promotion-list .layer .text .tit{font-size: 18px;}
}
@include media-breakpoint-down(md) {
    section.promotion{
        padding: 100px 0;
        h2{font-size: 46px;line-height: 1.16;margin-left: 62px;}
        .promotion-wrapper{
            .mCSB_horizontal{
                .mCSB_scrollTools.mCSB_scrollTools_horizontal{left: 62px;height: 4px;}
                .mCSB_container{margin-bottom: 40px;}
                .mCSB_draggerRail{padding-left: 62px;height: 4px;margin: 0;background: rgba(0,0,0,0.1);}
                .mCSB_dragger{margin: 0;}
                .mCSB_dragger_bar{margin: 0;background: #fff !important;border-radius: 0;opacity: 1 !important;}
            }
        }
        .promotion-list{
            padding-left: 30px;width: auto;
            .wrap{display: block;margin: 0 -6px}
            .left,
            .right{max-width: none;padding: 0 6px;}
            .right{
                margin-top: 12px;
                .d-flex{ margin: 0 -6px;}
                .d-flex:nth-child(1){margin-bottom: 12px;}
            }
            .column-lg,
            .column-sm{padding: 0 6px;}
            .item:hover{
                .layer{visibility: hidden;opacity: 0;}
            }
        }
    }
}

section.life{
    padding: 195px 0;
    .social-list{padding-right: 60px;}
    .life-list{
        padding-left: 220px;overflow: hidden;
        .wrap{margin: 0 -12px;display: flex;}
        .left{
            flex: 0 0 46.823529412%;max-width: 46.823529412%;padding: 0 12px;
            .inner{width: 100%;height: 100%;overflow: hidden;}
            .d-flex{
                height: calc(100% + 24px);margin: -12px;
            }
            .item{width: 50%;height: 50%;display: block;position: relative;padding: 12px;}
        }
        .right{
            flex: 0 0 53.176470588%;max-width: 53.176470588%;padding: 0 12px;
            .item{width: 100%;height: 100%;display: block;position: relative;}
        }
        ul{display: flex;}
        .img{
            display: block;overflow: hidden;width: 100%;height: 100%;padding-bottom: 100%;position: relative;
            img{vertical-align: top;width: 100%;height: 100%;object-fit: cover;position: absolute;}
        }
        .channel{position: absolute;right: 29px;bottom: 29px;
            .icon-instagram{width: 32px;height: 32px;vertical-align: top;}
            .icon-navershop{width: 29px;height: 29px;vertical-align: top;}
        }
        .text{
            position: absolute;left: 0;bottom: 0;right: 0;padding: 60px 80px;
            .event-name{display: inline-block;font-size: 1rem;font-weight: 700;color: #fff;border-bottom: 1px solid #fff;line-height: 1.3;}
            .tit{font-size: 26px;font-weight: 300;color: #fff;margin-top: 10px;}
            .text-tag{color: rgba(255, 255, 255, 0.6);font-size: 14px;font-weight: 300;margin-bottom: 5px;margin-top: 15px;}
            .channel{margin-top: 30px;position: static;}
        }
        .layer{
            background: #111111;color: #fff;position: absolute;left: 12px;top: 12px;right: 12px;bottom: 12px;padding: 30px;position: absolute;visibility: hidden;opacity: 0;transition: opacity .2s;overflow: hidden;
            .user{
                display: flex;align-items: center;margin-bottom: 30px;
                .thumb{width: 40px;height: 40px;border-radius: 50%;overflow: hidden;background: #fff;}
                .name{font-size: 1rem;margin-left: 10px;}
            }
            .contents{font-size: 14px;font-weight: 300;line-height: 1.57;text-align: center;}
            .share{
                position: absolute;bottom: 30px;right: 30px;
                i{vertical-align: top;}
            }
        }
        .item:hover{
            .layer{visibility: visible;opacity: 1;}
            .img img{transform: scale(1.05);}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    section.life .life-list{padding-left: 172px;}
}
@include media-breakpoint-down(xxl) {
    section.life{padding: 140px 0;}
    section.life .life-list{padding-left: 166px;}
    section.life .life-list .layer .contents{max-height: 9.42em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 6;-webkit-box-orient: vertical;}
}
@include media-breakpoint-down(xl) {
    section.life{padding: 140px 0;}
    section.life .life-list{padding-left: 158px;}
    section.life .life-list .layer .contents{max-height: 7.85em;-webkit-line-clamp: 5;}
    section.life .life-list .text{padding: 50px 60px;}
}
@include media-breakpoint-down(lg) {
    section.life{padding: 109px 0;}
    section.life .life-list{padding-left: 138px;}
    section.life .life-list .layer{padding: 15px;}
    section.life .life-list .layer .contents{max-height: 6.28em;-webkit-line-clamp: 4;}
    section.life .life-list .text{padding: 40px 40px;}
}
@include media-breakpoint-down(md) {
    section.life{
        padding: 100px 0;
        .social-list{padding-right: 0;margin-left: 60px;margin-top: 16px;}
        .life-list{
            padding-left: 30px;
            .wrap{display: block;margin: 0 -3px;}
            .left,
            .right{max-width: none;padding: 0 3px;}
            .left{
                .item{padding: 3px;}
                .d-flex{margin: -3px -3px 3px;height: auto;}
            }
            .channel{position: absolute;right: 12px;bottom: 12px;
                .icon-instagram{width: 15px;height: 15px;vertical-align: top;}
                .icon-navershop{width: 29px;height: 29px;vertical-align: top;}
            }
            .text{padding: 30px;
                .event-name{font-size: 12px;}
                .tit{font-size: 16px;}
                .text-tag{font-size: 12px;}
            }
            .layer{left: 3px;right: 3px;top: 3px;bottom: 3px;padding: 12px;
                .user{
                    .thumb{width: 30px;height: 30px;}
                    .name{font-size: 13px;}
                }
                .contents{font-size: 12px;}
                .share{
                    bottom: 15px;right: 15px;
                    i{width: 15px;height: 15px;}
                }
            }
        }
    }
}

section.redesign{
    background: #d9ddf1;position: relative;height: 940px;overflow: hidden;
    .section-title{font-family: 'NanumBarunGothic';font-size: 200px;line-height: 1;font-weight: 200;position: absolute;left: 80%;top: 250px;white-space: nowrap;z-index: 2;margin-left: 0;}
    .text{
        padding-left: 322px;position: absolute;left: 0;top: 530px;z-index: 2;
        .desc{font-size: 18px;font-weight: 300;line-height: 1.78;margin-bottom: 35px;max-width: 530px;word-break: keep-all;
            br{display: none;}
        }
        a{font-size: 18px;font-weight: 700;
            &::after{content: '';display: inline-block;width: 6px;height: 12px;background: url(../images/icon-view-more.svg) no-repeat 50% 50%;margin: 0 0 0 10px;transform: translateY(1px);border-radius: 1.5px;}
        }
    }
    .imgs{
        position: absolute;left: 47%;top: 50%;transform: translateY(-50%);
        .inner{display: flex;transform: translate3d(-100%, 0px, 0px)}
        .img{margin-right: 34px;}
        .img:nth-child(1){transform: translateY(-30px);}
        .img:nth-child(2){transform: translateY(70px);}
        .img:nth-child(3){transform: translateY(10px);}
        img{max-width: none;}
    }
}
@include media-breakpoint-down(xxxl) {
    section.redesign{height: 776px;}
    section.redesign .section-title{font-size: 150px;top: 220px;}
    section.redesign .imgs .img{margin-right: 30px;}
    section.redesign .imgs img{width: 304px;}
    section.redesign .text{padding-left: 248px;top: 430px}
}
@include media-breakpoint-down(xxl) {
    section.redesign{height: 712px;}
    section.redesign .section-title{font-size: 150px;top: 180px;}
    section.redesign .imgs img{width: 288px;}
    section.redesign .text{padding-left: 238px;top: 390px}
}
@include media-breakpoint-down(xl) {
    section.redesign{height: 670px;}
    section.redesign .section-title{font-size: 140px;}
    section.redesign .imgs img{width: 268px;}
    section.redesign .text{padding-left: 224px;}
}
@include media-breakpoint-down(lg) {
    section.redesign{height: 640px;}
    section.redesign .section-title{font-size: 140px;top: 169px;}
    section.redesign .imgs img{width: 220px;}
    section.redesign .text{padding-left: 190px;top: 370px}
}
@include media-breakpoint-down(md) {
    section.redesign{
        padding: 100px 0;height: auto;
        .container{position: relative;}
        .section-title{font-size: 100px;top: 100px;}
        .text{
            padding-left: 60px;padding-right: 25px;position: static;padding-top: 400px;
            .desc{font-size: 14px;line-height: 1.7;word-break: keep-all;
                br{display: none;}
            }
            a{font-size: 14px;}
        }
        .imgs{
            top: 30%;
            .img{position: static;margin-right: 17px;}
            img{width: 200px;}
        }
    }
}

section.event{
    padding-top: 200px;background: #fff;position: relative;
    .event-list{
        ul{
            display: flex;
        }
        .img{
            width: 100%;height: 0;padding-bottom: 100%;background: #f0f0f0;position: relative;
            img{vertical-align: top;width: 100%;height: 100%;object-fit: cover;position: absolute;left: 0;top: 0;}
            .notice{
                width: 100%;height: 0;padding-bottom: 100%;
                background: #bbb;color: #fff;position: relative;
                div{
                    position: absolute;top: 45%;left: 40px;right: 40px;text-align: left;
                    img{object-fit: none;width: auto;height: auto;}
                    p{font-size: 20px;font-weight: 700;line-height: 1.5;letter-spacing: -0.7px;margin-top: 30px;max-height: 4.5em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
                }
            }
        }
        .text{
            margin-top: 25px;
            .category{font-size: 14px;margin-bottom: 10px;color: $danger;font-family: 'Segoe UI';font-weight: 700;}
            .tit{font-size: 16px;font-weight: 700;letter-spacing: -0.45px;line-height: 1.75;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;max-height: 3.5em;}
            .date{line-height: 1;color: #999;margin-top: 10px;}
        }
        .swiper-container{padding-bottom: 60px;}
        .swiper-slide{width: 340px !important;}
        .swiper-wrapper:hover{
            & ~ .swiper-scrollbar{opacity: 1 !important;}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    section.event .event-list .swiper-slide{width: 254px !important;}
}
@include media-breakpoint-down(xxl) {
    section.event .event-list .swiper-slide{width: 240px !important;}
}
@include media-breakpoint-down(xl) {
    section.event .event-list .swiper-slide{width: 224px !important;}
}
@include media-breakpoint-down(lg) {

}
@include media-breakpoint-down(md) {
    section.event{
        padding-top: 100px;
        .event-list{
            .img{height: 180px;
                .notice{
                    div{
                        left: 20px;top: 38%;
                        p{font-size: 12px;margin-top: 17px;}
                    }
                }
            }
            .text{
                padding: 0;
                .category{font-size: 12px;}
                .tit{font-size: 14px;}
            }
            .swiper-container{padding-bottom: 40px;}
            .swiper-slide{width: 180px !important;}
        }
    }
}

.body-main{
    .pop_class{margin-top: 0;margin-bottom: 0;}
}
.main-popup{
    background: #fff;
    .control{text-align: right;height: 60px;line-height: 60px;padding: 0 30px;
        a{position: relative;padding-left: 21px;margin-left: 16px;}
        a + a::before{content: '';display: block;width: 2px;height: 13px;background: #ddd;position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
    }
}
@include media-breakpoint-down(md) {
    .main-popup{
        .control{height: 50px;line-height: 50px;padding: 0 25px;}
    }
}