// Text truncate
// Requires inline-block or block for proper styling

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-multi($font-size, $line-height, $lines-to-show) {
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  height: $line-height*$lines-to-show; // Fallback for non-webkit
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
