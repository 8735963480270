.board-list{
    border-top: 2px solid #000;
    > ul{
        > li{
            padding: 40px 40px;border-bottom: 1px solid #ddd;position: relative;transition: all .15s;
            &:hover{background: #f6f6f6;}
        }
    }
    .tit{
        font-size: 26px;letter-spacing: -0.55px;color: #111;
        .q{margin-left: -40px;font-weight: 700;padding-left: 40px;}
    }
    .date{font-size: 18px;color: #999;margin-top: 5px;}
    .category{color: $danger;font-size: 16px;font-weight: 700;margin-bottom: 6px;font-family: 'Segoe UI';}
    .badge-wrap{margin: -1px;display: flex;flex-wrap: wrap;padding-bottom: 25px;
        .badge{margin: 1px;}
    }
}
@include media-breakpoint-down(md) {
    .board-list{
        border-top: 3px solid #000;margin-right: -20px;
        > ul{
            > li{padding: 30px 20px 30px 0;}
        }
        .tit{font-size: 16px;}
        .date{font-size: 12px;}
        .category{font-size: 14px;}
        .share{display: none;}
        .badge-wrap{padding-bottom: 12px;}
    }
}

.more{
    text-align: center;
    a{display: inline-flex;align-items: center;justify-content: center;color: #666;text-decoration: none;
        &:hover{color: #000;
            i{border-color: #000;}
        }
        i{margin-top: -4px;margin-left: 10px;}
    }
}
@include media-breakpoint-down(md) {
    .more{
        a{font-size: 13px;}
    }
}


.board-search{
    height: 60px;border: 2px solid #000;margin-left: -4px;
    > .row{height: 100%;
        [class^=col]{height: 100%;}
        .col-auto + .col{
            &::before{content: '';display: block;vertical-align: middle;width: 2px;height: 24px;background: #000;position: absolute;left: 0;top: 50%;margin-top: -12px;}
        }
    }
    .custom-select,
    .form-control{border: 0;height: 100%;}
    button{padding: 0 20px;height: 100%;}
    .icon-search{width: 21px;height: 21px;}
    .btn-faq-keyword-del{padding: 5px;height: auto;margin-right: 10px;}
    .icon-del{line-height: 1;vertical-align: middle;}
}
@include media-breakpoint-down(md) {
    .board-search{
        height: auto;border: 0;display: none;
        button{padding: 0;
            .icon-search{width: 27px;height: 27px;}
        }
    }
}

.board-detail{
    border-top: 4px solid $dark;border-bottom: 1px solid $dark;margin-bottom: 60px;
    .board-header{
        border-bottom: 1px solid $dark;padding: 50px 260px 40px 47px;position: relative;
        .thumb{width: 110px;height: 110px;border: 1px solid #ddd;
            img{width: 100%;height: 100%;object-fit: cover;}
        }
        .subject{font-size: 30px;font-weight: 700;letter-spacing: -0.75px;color: #333;margin-bottom: 10px;}
        .date{
            color: #999;display: flex;align-items: center;
            span{position: relative;}
            span + span::before{content: '';display: inline-block;vertical-align: middle;width: 2px;height: 15px;background: #cfcfcf;margin: 0 10px;transform: translateY(-1px);}
        }
        .badge-wrap{padding-bottom: 15px;}
        .category{color: $danger;font-weight: 700;font-family: 'Segoe UI';margin-bottom: 8px;line-height: 1;}
        .button{position: absolute;right: 40px;top: 50%;transform: translateY(-50%);}
    }
    .board-body{
        padding: 80px 40px;min-height: 300px;color: #333;
        .a{font-weight: 700;font-size: 26px;margin-bottom: 3px;}
    }
    .board-file{
        background: #f2f2f2;padding: 37px 30px;display: flex;
        p{font-weight: 700;color: #333;display: inline-block;vertical-align: middle;flex: 0 0 auto;width: 110px;}
        a{vertical-align: middle;margin-right: 20px;flex-basis: 0;flex-grow: 1;}
    }
    .board-nav{
        border-top: 1px solid #000;
        .d-flex{padding: 36px 0;}
        .d-flex:first-of-type{border-bottom: 1px solid #ddd;}
        .label{color: #999;font-size: 16px;width: 140px;flex: 0 0 140px;padding-left: 30px;}
        .tit{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
        .tit a{font-size: 16px;color: #666;letter-spacing: -0.77px;}
    }
}
@include media-breakpoint-down(md) {
    .board-detail{
        margin-bottom: 40px;border-top: 3px solid #000;
        .board-header{
            padding: 25px 50px 25px 10px;
            .thumb{width: 90px;height: 90px;}
            .subject{font-size: 18px;margin-bottom: 5px;line-height: 1.37;}
            .share{right: 0;}
            .button{position: static;transform: none;margin-top: 25px;
                .btn{display: block;}
            }
            &.mobile-padding-right{
                padding-right: 10px;
            }
        }
        .board-body{
            padding: 25px 10px;min-height: 0;font-size: 14px;
            .a{font-size: 16px;}
        }
        .board-file{
            padding: 25px 20px;
            p{margin-right: 25px;}
            a{margin-right: 15px;}
        }
        .board-nav{
            .d-flex{display: block !important;padding: 15px 0;}
            .label{display: block;width: auto;font-size: 13px;padding-left: 0;margin-bottom: 3px;}
            .tit a{font-size: 14px;}
        }
    }
}

.gallery-list{
    > ul{
        display: flex;flex-wrap: wrap;margin: 0 -12px;
        > li{flex: 0 0 33.33333%;max-width: 33.33333%;padding: 12px;margin-bottom: 110px;}
    }
    .img{
        img{vertical-align: top;width: 100%;height: 100%;object-fit: cover;}
        .notice{
            width: 100%;height: 0;padding-bottom: 100%;
            background: #bbb;color: #fff;position: relative;
            div{
                position: absolute;top: 35%;left: 50px;right: 50px;text-align: left;
                img{object-fit: none;width: auto;height: auto;}
                p{font-size: 28px;font-weight: 700;line-height: 1.43;letter-spacing: -0.7px;margin-top: 30px;max-height: 4.29em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
            }
        }
    }
    .text{
        position: relative;padding-top: 37px;
        .category{color: $danger;font-size: 16px;font-weight: 700;margin-bottom: 8px;font-family: 'Segoe UI', 'NanumBarunGothic';
            span{font-size: 14px;font-weight: 400;}
        }
        .tit{font-size: 22px;font-weight: 700;letter-spacing: -0.55px;padding-right: 70px;line-height: 1.5;max-height: 3em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
        .date{font-size: 18px;color: #999;letter-spacing: -0.45px;line-height: 1;margin-top: 20px;}
    }
    .share{
        transform: none;top: 60px;
        /* .list{right: 0;top: 48px;transform: none;
            &::before{border-width: 0 5px 6px 5px;border-color: transparent transparent #000000 transparent;margin-top: 0;right: 20px;top: -6px;}
        } */
    }
}
@include media-breakpoint-down(md) {
    .gallery-list{
        margin-right: -20px;
        > ul{
            display: block;margin: 0;
            > li{padding: 0;max-width: none;margin-bottom: 50px;}
        }
        .img{
            .notice{
                padding-bottom: 70.588235294%;
                div{left: 30px;}
                div p{font-size: 18px;margin-top: 20px;}
            }
        }
        .text{
            padding-top: 18px;
            .tit{font-size: 16px;letter-spacing: -0.8px;padding-right: 50px;}
            .date{font-size: 14px;}
        }
        .share{top: 36px;right: 5px;}
    }
}

.product-list{
    margin-right: -62px;overflow: hidden;
    > ul{
        display: flex;flex-wrap: wrap;margin: 0 -.5px;
        > li{flex: 0 0 25%;max-width: 25%;padding: 0 .5px;margin-bottom: 90px;}
    }
    .item{position: relative;}
    .new{display: inline-block;background: $danger;color: #fff;height: 30px;padding: 0 10px;line-height: 29px;font-family: 'Segoe UI';font-size: 14px;font-weight: 700;position: absolute;left: 0;top: 20px;z-index: 1;}
    .img{
        position: relative;width: 100%;height: 0;padding-bottom: 100%;background-color: #f0f0f0;
        img{vertical-align: top;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
        .img-back{transition: opacity .15s ease-in;}
        .img-front{visibility: hidden;opacity: 0;transition: opacity .15s ease-in;width: 100%;height: 100%;object-fit: cover;}
        .compare-check{position: absolute;top: 16px;right: 20px;
            &:hover{
                label{color: #000;}
                label::before{background-image: url('../images/icon-compare-active.png');}
            }
            label{text-indent: -9999px;}
        }
        &:focus,
        &:hover{
            .img-front{visibility: visible;opacity: 1;}
            .compare-check{
                label{text-indent: 0;}
            }
        }
    }
    .text{
        position: relative;padding-top: 37px;padding-right: 10%;
        .category{color: $danger;font-size: 14px;font-weight: 700;margin-bottom: 10px;line-height: 1;font-family: 'Segoe UI';}
        .tit{font-size:18px;font-weight: 700;letter-spacing: -0.45px;line-height: 1.5;max-height: 3em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
        .desc{font-size: 16px;color: #999;letter-spacing: -0.45px;margin-top: 10px;}
    }
}
@include media-breakpoint-down(xl) {
    .product-list{
        > ul{
            > li{flex: 0 0 33.33333%;max-width: 33.33333%;}
        }
        .text{padding: 20px;}
    }
}
@include media-breakpoint-down(md) {
    .product-list{
        margin-right: -20px;margin-left: -20px;
        > ul{
            > li{flex: 0 0 50%;max-width: 50%;margin-bottom: 30px;}
        }
        .new{height: 20px;line-height: 19px;padding: 0 6px;}
        .img{
            .compare-check{right: 10px;top: 5px;}
            .img-back{max-width: 80%;}
        }
        .text{
            .category{font-size: 12px;}
            .tit{font-size: 14px;}
            .desc{font-size: 13px;margin-top: 8px;}
        }
    }
}

.board-count{text-align: right;color: #666;margin-top: 40px;margin-bottom: 16px;}
@include media-breakpoint-down(md) {
    .board-count{margin-top: 25px;}
}

.download-category{margin-bottom: 50px;}
@include media-breakpoint-down(md) {
    .download-category{
        margin-right: -20px;margin-bottom: 25px;
        .row-12{margin-left: -1px;margin-right: -1px;}
        [class^=col]{padding-left: 1px;padding-right: 1px;}
        .col-12{margin-bottom: 1px;}
    }
}
.download-detail{
    padding: 25px 50px;
    .table{
        color: #666;line-height: 1.66;border-bottom: 0;
        tr:first-child{
            th,
            td{border-top: 0;}
        }
        tr th{background: transparent;}
    }
}
@include media-breakpoint-down(md) {
    .download-detail{
        padding: 0;
        .table,
        .table{
            thead,
            tbody,
            tr,
            th,
            td{display: block;}
            th,
            td{border-top: 0;padding: 3px 0;}
            tr{border-bottom: 1px solid #ddd;padding: 20px 10px;}
            tr:last-of-type{border-bottom: 0;}
        }
    }
}

.faq-category{margin-bottom: 50px;}
.faq-category-sub{
    margin-top: 20px;
    .inner{display: flex;flex-wrap: wrap;align-items: center;margin: -10px -15px;}
    background: #f6f6f6;padding: 30px;
    .custom-control-inline{margin: 10px 15px;}
}
@include media-breakpoint-down(md) {
    .faq-category{margin-bottom: 25px;}
    .faq-category-sub{
        margin-right: -20px;margin-left: -20px;padding: 20px;
        .inner{margin: -10px;}
        .custom-control-inline{margin: 10px 10px;}
    }
}

.search-page{
    .category-area{margin-top: 150px;}
    .product-list{
        margin-right: 0;
        > ul{
            margin: 0 -12px;
            > li{
                padding: 0 12px;
            }
        }
    }
    .board-list{margin-bottom: 50px;}
}
@include media-breakpoint-down(md) {
    .search-page{
        .category-area{margin-top: 70px;}
        .product-list{
            margin: 0;
            > ul{
                margin: 0 -5px;
                >li{padding: 0 5px;margin-bottom: 0;}
            }
        }
        .board-list{margin-right: 0;margin-bottom: 25px;}
    }
}

.search-result{font-size: 60px;
    i{color: #999;font-weight: 400;}
}
@include media-breakpoint-down(xl) {
    .search-result{font-size: 46px;}
}
@include media-breakpoint-down(md) {
    .search-result{font-size: 24px;}
}
.search-empty{
    margin-top: 75px;
    strong{font-size: 32px;font-weight: 700;letter-spacing: -0.8px;display: block;margin-bottom: 40px;}
    ul{
        li{
            color: #666;line-height: 2.22;letter-spacing: -0.45px;font-size: 18px;padding-left: 12px;position: relative;
            &::before{content: '';display: inline-block;vertical-align: middle;width: 5px;height: 5px;background-color: #666;border-radius: 50%;position: absolute;left: 0;top: 15px;}
        }
    }
}
@include media-breakpoint-down(md) {
    .search-empty{
        margin-top: 40px;
        ul{
            li{font-size: 14px;line-height: 1.6;margin: 6px 0;padding-left: 10px;
                &::before{width: 4px;height: 4px;top: 8px;}
            }
        }
    }
}

.category-area{margin-bottom: 47px;margin-top: 98px;}
.product-category{
    font-weight: 700;font-size: 32px;line-height: 1;
    span{font-weight: 400;}
    .title-area + &{margin-top: 95px;}
}
@include media-breakpoint-down(md) {
    .category-area{margin-top: 35px;margin-bottom: 20px;}
    .product-category{
        font-size: 19px;
        //.title-area + &{margin-top: 95px;}
    }
}

.product-top-banner{
    background-repeat: no-repeat;background-position: center;background-size: cover;height: 180px;
    > a{
        width: 100%;height: 100%;display: flex;align-items: center;justify-content: flex-end;padding-right: 262px;text-decoration: none;
        span{color: rgba(255,255,255,0.6);font-size: 18px;letter-spacing: -0.45px;display: block;margin-bottom: 8px;}
        strong{color: #fff;font-size: 22px;letter-spacing: -0.55px;position: relative;
            .icon-arrow-right{border-color: #fff;margin-top: -1px;margin-left: 2px;}
            .line{content: '';width: 0;height: 2px;position: absolute;left: 0;bottom: -5px;background-color: #fff;transition: .4s .05s;}
        }
        &:hover,
        &:focus{
            .line{width: 100%;}
        }
    }
}
@include media-breakpoint-down(md) {
    .product-top-banner{
        background-position: 8% 50%;height: 175px;
        > a{
            padding: 37px 22px;justify-content: start;align-items:flex-end;
            span{font-size: 14px;}
            strong{font-size: 17px;}
        }
    }
}

.compare-check{
    input{
        position: absolute;opacity: 0;
        &:checked ~ {
            label{color: #000;
                &::before{background-image: url('../images/icon-compare-active.png');}
            }
        }
    }
    label{
        color: #999;line-height: 1;font-size: 14px;letter-spacing: 0.35px;cursor: pointer;position: relative;margin: 0;padding: 5px 5px 5px 33px;
        &::before{content: '';display: block;background: url('../images/icon-compare.png') no-repeat 0 0;background-size: contain;width: 26px;height: 16px;position: absolute;left: 0;top: 3px;}
    }
}
@include media-breakpoint-down(md) {
    .compare-check {
        label{font-size: 0;color: transparent;padding: 10px;
            &::before{width: 21px;height: 13px;position: static;}
        }
    }
}

.compare{
    position: fixed;left: 62px;top: 50%;z-index: 1;transform: translateY(-50%);transition: transform .3s .1s;
    .selected-length{
        width: 80px;height: 80px;background: $danger;color: #fff;font-size: 20px;font-weight: 700;font-family: 'Segoe UI';display: flex;align-items: center;justify-content: center;
        &::before{content: '';display: block;background: url('../images/icon-compare-floating.png') no-repeat 0 0;background-size: contain;width: 25px;height: 16px;margin: 0 5px;transform: translateY(1px);}
    }
    .expanded{
        width: 160px;background: #fff;box-shadow: 0px 14px 40px 0 rgba(0, 0, 0, 0.1);padding: 30px 0 0;transform: translateX(calc(-100% - 160px));transition: .5s ease-out;position: absolute;left: 0;top: 50%;margin-top: -250px;
        .item{
            position: relative;width: 100px;margin: 0 auto;
            &.selected{
                .img .inner::before,
                .img .inner::after{display: none;}
                .del{display: block;}
            }
        }
        .item + .item{border-top: 1px solid #eee;padding-top: 20px;margin-top: 25px;
            .del{top: 20px;}
        }
        .img{
            width: 100px;height: 95px;display: flex;align-items: center;justify-content: center;
            .inner{
                width: 100%;height: 100%;position: relative;text-align: center;
                &::before{content: '';display: block;border: 2px dashed #eee;width: 100%;height: 100%;position: absolute;left: 0;top: 0;}
                &::after{content: '비교제품 선택';display: flex;width: 100%;height: 100%;align-items: center;justify-content: center;word-break: keep-all;position: absolute;left: 0;top: 0;padding: 0 12px;text-align: center;color: #bbb;line-height: 1.25;}
                img{position: relative;z-index: 1;width: 80px;height: 80px;object-fit: cover;background: #fff;}
                .model{font-size: 14px;font-weight: 700;font-family: 'Segoe UI';color: #999;text-align: center;line-height: 1.24;max-height: 2.48em; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;word-break: break-all;}
            }
        }
        .del{
            background: #f6f6f6;width: 21px;height: 21px;border-radius: 50%;position: absolute;right: 0;top: 0;z-index: 2;display: none;
            .icon-del{width: 9px;height: 9px;}
        }
        .button{
            margin-top: 30px;
            .btn{width: 100%;
                span{display: inline;font-size: 1.125rem;}
            }
        }
    }
    &.active{
        .expanded{transform: translateX(0);}
    }
    .button{
        .btn{
            height: 80px;width: 80px;min-width: 0;
            span{display: block;font-size: 16px;line-height: 1.25;}
        }
    }
}
@include media-breakpoint-down(md) {
    .compare{
        top: auto;left: 0;bottom: 0;transform: none;transition: bottom .4s ease-in;
        .selected-length{
            width: 50px;height: 50px;font-size: 14px;transition: transform .3s .24s ease-out;
            &::before{width: 21px;height: 13px;margin: 0 3px 0 0;transform: translateX(0);}
        }
        .expanded{
            box-shadow: 0px -14px 40px 0 rgba(0, 0, 0, 0.1);padding-top: 20px;top: auto;bottom: -200px;margin-top: 0;width: 100vw;transform: none;height: 190px;
            > div{display: flex;flex-wrap: wrap;}
            .item{
                margin: 0 !important;flex: 1;padding: 0 16px;
                &.selected{
                    .del{right: 12px;}
                }
            }
            .item + .item{border: 0;border-left: 1px solid #eee;padding-top: 0;
                .del{top: 0;}
            }
            .img{width: 85px;height: 80px;margin: 0 auto;
                .inner{
                    .model{}
                }
            }
            .button{
                width: 100%;margin: 0;padding-top: 40px;
                .btn{
                    height: 50px;
                    padding: 13px 0 calc(constant(safe-area-inset-bottom) + 13px);
                    padding: 13px 0 calc(env(safe-area-inset-bottom) + 13px);
                }
            }
        }
        .button{
            .btn{
                width: 50px;height: 50px;padding: 3px 0 0;
                span{font-size: 12px;}
            }
        }
        &.active{
            right: 0;
            .selected-length{display: flex;transform: translateY(-140px);}
            .expanded{
                bottom: 0;
            }
        }
        html.is-bottom &{
            bottom: 317px;
            bottom: calc(constant(safe-area-inset-bottom) + 317px);
            bottom: calc(env(safe-area-inset-bottom) + 317px);
            .expanded{
                bottom: -540px;
                .button{
                    .btn{padding: 3px 0 0;}
                }
            }
            &.active .expanded{bottom: 0;}
        }
    }
}

.compare-product{
    position: relative;padding: 80px 60px;
    &::before{content: '';width: 250px;position: absolute;left: 0;top: 0;bottom: 0;background: #000;}
    h1{position: absolute;left: 60px;top: 75px;line-height: 1.26;color: #fff;font-size: 38px;font-weight: 700;}
    .scroll-y-dark{
        border-top: 3px solid #000;position: relative;max-height: 450px;
        &::before{content: '';width: 190px;height: 3px;background: #fff;position: absolute;left: 0;top: -3px;}
    }
    .mCustomScrollBox{padding-right: 35px;margin-right: -35px;max-width: none;}
    .mCSB_inside>.mCSB_container{margin-right: 0;}
}
@include media-breakpoint-down(md) {
    .compare-product{
        padding: 60px 0 60px 20px;
        &::before{width: 100px;}
        h1{font-size: 25px;left: 20px;}
        .scroll-y-dark{
            max-height: 200px;
            &::before{width: 80px;}
        }
        .mCSB_scrollTools.mCSB_scrollTools_horizontal{left: 100px;}
    }
}
.table-compare-thead{
    display: flex;padding-left: 190px;width: 100%;margin-bottom: 35px;
    .th{flex-grow: 1;text-align: center;}
    .item{
        position: relative;
        .img{
            width: 135px;height: 135px;display: inline-block;
            img{width: 100%;height: 100%;object-fit: cover;}
        }
        .model{font-family: 'Segoe UI';font-size: 16px;font-weight: 700;color: $danger;margin-top: 8px;}
        .name{font-size: 19px;font-weight: 700;margin-top: 3px;}
        .btn-del{position: absolute;left: calc(50% + 80px);top: 0;background-color: #f6f6f6;border-radius: 50%;display: flex;align-items: center;justify-content: center;width: 31px;height: 31px;
            &:hover,
            &:focus{background: #eee;}
        }
    }
}
.table-compare-tfoot{
    display: flex;padding-left: 13.95%;width: 100%;margin-top: 10px;
    .td{flex-grow: 1;text-align: center;}
}
.table-compare{
    position: relative;table-layout: fixed;border-bottom: 0;
    th,
    td{font-size: 18px;}
    thead{
        th{border-top: 0;}
    }
    tbody{
        th{color: #fff;border-top: 1px solid #333;background: transparent !important;}
        th:first-child{width: 190px;}
        td{color: #666;border-top: 1px solid #eee;text-align: center;}
        /* tr:first-child{
            th{border-top: 3px solid #fff;}
            td{border-top: 3px solid #000;}
        } */
    }
}
@include media-breakpoint-down(md) {
    .table-compare-thead{
        padding-left: 0;display: table-header-group;
        .item{
            padding-left: 5px;padding-right: 5px;text-align: center;
            .img{
                width: 100px;height: 100px;
            }
            .model{font-size: 12px;}
            .name{font-size: 14px;}
            .btn-del{width: 25px;height: 25px;left: calc(50% + 25px);}
        }
    }
    .table-scroll{
        position: relative;overflow: hidden;
        .inner{width: calc(100% - 80px);overflow-x: scroll;margin-left: 80px;overflow-y: hidden;padding-bottom: 20px;}
        .table-compare{
            font-size: 13px;position: static;
            th,
            td{vertical-align: middle;line-height: 1.3;font-size: 12px;width: calc((100vw - 100px) / 2);padding: 15px;height:46px}
            .cell-fixed{position: absolute;top: auto;padding-top: 15px;padding-bottom: 15px;background: transparent;}
            .cell-fst{width: 80px;left: 0;text-align: left;padding-left: 0;padding-right: 0;border-color: #333;}
            thead{
                th{border: 0;background: transparent;}
            }
            tbody{
                .cell-fixed{transform: translateY(-1px);}
            }
        }
    }
    .table-compare-tfoot{
        padding-left: 0;display: table-footer-group;
        .btn{font-size: 12px;padding-top: 10px;padding-bottom: 10px;}
    }
}

.opensource{
    padding-top: 35px;
    .form-row{
        padding: 20px 0;
        & + .form-row{border-top: 1px solid #000;}
        label{width: 320px;margin-bottom: 0;font-size: 22px;font-weight: 700;}
        .custom-select{width: 40%;}
        ul{
            padding: 12px 0;
            li{line-height: 2.22;}
            a{
                display: inline-block;text-decoration: none;color: #666;padding-left: 14px;position: relative;font-size: 18px;
                &::before{content: '';display: block;width: 5px;height: 5px;background: #999;border-radius: 50%;position: absolute;left: 0;top: 50%;margin-top: -4px;}
                span{position: relative;
                    &::before{content: '';width: 0;height: 1px;position: absolute;left: 0;bottom: -2px;background: #000;transition: .2s;}
                }
            }
            a:focus,
            a:hover{
                color: #000;
                &::before{background: #000;}
                span{
                    &::before{width: 100%;}
                }
            }
        }
    }
    .license{
        background: #f6f6f6;border: 1px solid #ddd;padding: 50px;margin-bottom: 50px;
        .scroll-y-dark{max-height: 404px;}
        pre{white-space: pre-wrap;}
    }
}
@include media-breakpoint-down(xl) {
    .opensource .form-row .custom-select{width: 80%;}
}
@include media-breakpoint-down(md) {
    .opensource .form-row label{width: 140px;font-size: 15px;}
    .opensource .form-row .custom-select{width: 100%;}
    .opensource .form-row ul{padding: 0;}
    .opensource .form-row ul a{font-size: 15px;}
    .opensource .license{padding: 20px;margin-bottom: 25px;}
}

.as{
    .as-type{margin-bottom: 30px;padding-top: 20px;}
    .custom-radio{
        .custom-control-label{font-size: 22px;font-weight: 700;letter-spacing: -0.55px;line-height: 1.2;}
    }
    .form-box{
        background: #f9f9f9;padding: 100px 50px;
        .inner{max-width:933px;margin: 0 auto;}
        .form-row{
            label{width: 123px;font-weight: 700;font-size: 18px;margin-bottom: 0;}
        }
        .form-row + .form-row{margin-top: 10px;}
        .form-text-wrap{margin-left: 133px;margin-top: 15px;}
        .btn{height: 130px;}
    }
    .info-link{
        font-size: 14px;font-weight: 700;letter-spacing: -0.35px;color: #333;margin-top: 25px;display: flex;flex-wrap: wrap;
        .icon-arrow-right{width: 8px;height: 8px;}
        a{margin-right: 48px;display: inline;}
    }
}
@include media-breakpoint-down(xl) {
    .as{
        .form-box{
            .form-row{
                label{width: 100px;}
            }
            .form-text-wrap{margin-left: 100px;}
            .btn{min-width:150px}
        }
    }
}
@include media-breakpoint-down(lg) {
    .as{
        .form-box{
            .form-control,
            .custom-select{font-size: 16px;padding-left: 1rem;padding-right: 1rem;}

        }
    }
}
@include media-breakpoint-down(md) {
    .as{
        .as-type{margin-bottom: 25px;}
        .custom-radio{
            .custom-control-label{font-size: 16px;line-height: 1.4;}
        }
        .form-box{
            margin: 0 -20px;padding: 30px 20px;
            .form-row{
                label{font-size: 14px;margin-bottom: 5px;}
            }
            .form-row + .form-row{margin-top: 20px;}
            .form-text-wrap{margin-left: 0;}
            .form-text{font-size: 12px;word-break: keep-all;}
            .btn{width: 100%;height: auto;margin-top: 35px;}
        }
        .info-link{
            a{margin-right: 22px;margin-bottom: 15px;}
        }
    }
}

.as-step{
    margin: 100px 0 80px;
    ol{
        display: flex;align-items: center;justify-content: space-between;position: relative;margin: 0 -19px;
        &::before{content: '';width: 100%;height: 1px;background: #ddd;position: absolute;left: 0;right: 0;top: 50%;z-index: -1;}
        li{
            padding: 0 19px;background: #fff;
            > div{
                width: 160px;border: 2px solid #eee;border-radius: 50rem;height: 50px;background: #eee;position: relative;padding-left: 40px;
                span{
                    display: flex;align-items: center;justify-content: center;height: 100%;text-align: center;font-size: 20px;color: #bbb;letter-spacing: -0.5px;padding-top: 1px;
                    &::before{content: '';display: block;width: 40px;height: 40px;border-radius: 50%;background: #fff;color: #bbb;text-align: center;line-height: 38px;font-weight: 700;font-family: 'Segoe UI';font-size: 20px;position: absolute;left: 3px;top: 50%;transform: translateY(-50%);}
                }
            }
            &.active{
                > div{
                    border-color: #000;background: #fff;
                    span{color: #000;
                        &::before{background: #000;color: #fff;}
                    }
                }
            }
        }
        li:nth-child(1) span::before{content: '1';}
        li:nth-child(2) span::before{content: '2';}
        li:nth-child(3) span::before{content: '3';}
    }
}
@include media-breakpoint-down(md) {
    .as-step{
        margin: 30px 0 35px;
        ol{
            margin: 0 -5px;
            li{padding: 0 5px;
                > div{
                    height: 30px;width: 84px;padding-left: 25px;border-width: 1px;
                    span{font-size: 12px;
                        &::before{width: 24px;height: 24px;left: 2px;font-size: 12px;line-height: 23px;}
                    }
                }
            }
        }
    }
}

.as-status{
    margin-bottom: 50px;
    strong{font-size: 26px;font-weight: 700;letter-spacing: -0.65px;display: block;margin-bottom: 5px;}
    p{font-size: 18px;color: #666;letter-spacing: -0.45px;line-height: 1.67;}
}
@include media-breakpoint-down(md) {
    .as-status{
        strong{font-size: 19px;}
        p{font-size: 14px;}
    }
}

.table-as{
    letter-spacing: -0.45px;border-bottom: 1px solid #ddd;
    > tbody{
        th,
        td{padding-left: 40px;padding-right: 40px;font-size: 18px;}
        th{width: 360px;color: #444;vertical-align: middle;}
        td{color: #666;}
    }

    & ~ .table-as{margin-top: 20px;}
}
@include media-breakpoint-down(md) {
    .table-as{
        > tbody{
            th,
            td{padding-left: 10px;padding-right: 10px;font-size: 14px;}
            th{width: 120px;padding-left: 10px;}
        }
        .btn{min-width: auto;width: 100%;}
    }
}

.as-guide{
    margin-top: 80px;
    .guarantee{
        .col-xl-auto{margin-right: 130px;}
    }
}
@include media-breakpoint-down(xxxl) {
    .as-guide{
        .guarantee{
            .col-xl-auto{margin-right: 80px;}
        }
    }
}
@include media-breakpoint-down(md) {
    .as-guide{margin-top: 35px;}
    .guarantee{
        .col-12 + .col-12{margin-top: 22px;}
    }
}

.exclamation{
    background: #f6f6f6;padding: 12px 20px;display: flex;align-items: center;
    i{margin-right: 10px;flex: 0 0 auto;width: 25px;height: 25px;}
    p{flex-grow: 1;color: #333;}
    & + .subtitle{margin-top: 80px;}
}
@include media-breakpoint-down(md) {
    .exclamation{
        padding: 10px;
        .icon-info{width: 20px;height: 20px;}
    }
}

.product-detail{
    > div{padding: 150px 0;}
    @include media-breakpoint-down(md) {
        > div{padding: 50px 0;}
    }
}

.product-detail-top{
    border-bottom: 1px solid #ddd;
    .model{
        font-size: 18px;font-weight: 700;color: $danger;margin-bottom: 15px;font-family: 'Segoe UI';
        .icon-new-badge{transform: translateY(-1px);margin-left: 6px;}
    }
    .name{font-size: 60px;font-weight: 700;line-height: 1.2;letter-spacing: -1.5px;margin-bottom: 20px;}
    .desc{font-size: 22px;line-height: 1.55;color: #666;letter-spacing: -0.55px;margin-top: 45px;}
    .info{
        font-size: 16px;color: #999;letter-spacing: -0.4px;display: flex;align-items: center;margin-top: 10px;
        > span + span::before{content: '';display: inline-block;width: 2px;height: 14px;background: #ddd;margin: 0 10px;transform: translateY(2px);}
    }
    .btns{
        max-width: 410px;margin-top: 70px;
        .share{
            transform: none;position: relative;top: auto;right: auto;width: auto;height: auto;
            .btn-sns{border: 2px solid #000;width: 60px;height: 60px;display: flex;align-items: center;justify-content: center;}

            @include media-breakpoint-down(lg) {
                .list{
                    top: 100%;left: 50%;right: auto;transform: translate(-50%, 0);padding-top: 15px;
                    ul{
                        display: block;padding: 2px 0;
                        &::before{transform: rotate(-90deg);right: auto;top: -7px;left: 50%;margin:0;margin-left: -2.5px;}
                    }
                    li{padding: 1px 0;}
                    li+ li::before{width: 30px;height: 1px;top: 0;left: 50%;margin-top: 0;margin-left: -15px;}
                }
            }
            @include media-breakpoint-up(xl) {
                .list{
                    right: auto;top: 0;left: 100%;transform: none;padding-left: 15px;
                    ul{
                        padding: 2px 0;
                        &::before{transform: rotate(-180deg);top: 50%;left: 0;margin:0;margin-left: -5.5px;margin-top: -2.5px;}
                    }
                    li{padding: 1px 0;}
                    li+ li::before{width: 30px;height: 1px;top: 0;left: 50%;margin-top: 0;margin-left: -15px;}
                }
            }
        }
    }
    .product-imgs{
        position: relative;
        .large{
            width: 650px;height: 650px;display: flex;align-items: center;justify-content: center;background: #f2f2f2;
            img{width: 100%;height: 100%;object-fit: cover;}
            .video{
                width: 100%;height: 0;padding-bottom: 56.25%;position: relative;
                iframe,
                video{position: absolute;left: 0;top: 0;width: 100% !important;height: 100% !important;}
            }
        }
        .thumbs{
            display: flex;align-items: center;justify-content: center;position: absolute;bottom: 50px;left: 50%;transform: translateX(-50%);
            li{width: 60px;height: 60px;
                a{display: block;height: 100%;border: 1px solid #fff;}
                img{width: 100%;height: 100%;object-fit: cover;opacity: .6;transition: opacity .2s;}
                & + li{margin-left: 10px;}
                a.active{
                    img{opacity: 1;}
                }
            }
        }
    }
}
@include media-breakpoint-down(xxxl) {
    .product-detail-top{
        .name{font-size: 50px;}
        .desc{font-size: 19px;}
        .product-imgs{
            .large{width: 500px;height: 500px;}
        }
    }
}
@include media-breakpoint-down(xl) {
    .product-detail-top{
        .name{font-size: 42px;}
        .desc{font-size: 16px;}
        .btns{margin-top: 40px;}
        .product-imgs{
            .large{width: 420px;height: 420px;}
        }
    }
}
@include media-breakpoint-down(lg) {
    .product-detail-top{
        .desc{margin-top: 25px;}
        .info{font-size: 13px;}
        .btns{margin-top: 25px;max-width: none;}
        .product-imgs{
            height: 0;padding-bottom: 100%;position: relative;margin-top: 25px;
            .large{position: absolute;left: 0;top: 0;width: 100%;height: 100%;}
            .thumbs{bottom: 25px;}
        }
    }
}
@include media-breakpoint-down(md) {
    .product-detail-top{
        .model{font-size: 13px;}
        .name{font-size:30px;}
        .btns{
            .share{
                .btn-sns{width: 50px;height: 50px;}
            }
        }
        .product-imgs{
            margin-left: -20px;margin-right: -20px;
            .thumbs{
                li{width: 34px;height: 34px;}
            }
        }
    }
}

.product-detail-video{
    background: #000;margin: 0 -62px 0 -322px;
    @include media-breakpoint-down(xxxl) {
        margin-left: -220px;
    }
    @include media-breakpoint-down(md) {
        margin-left: -20px;margin-right: -20px;
    }

    .inner{max-width: 1000px;margin: 0 auto;}
    .video{
        width: 100%;height: 0;padding-bottom: 56.25%;position: relative;margin: 0 auto;
        iframe,
        video{position: absolute;left: 0;top: 0;width: 100% !important;height: 100% !important;}
    }
}
@include media-breakpoint-down(md) {
    .product-detail-video{
        margin-bottom: 25px;
        .inner{padding: 0 20px;}
    }
}

.product-detail-br{
    display: block;height: 1px;background: #ddd;padding: 0 !important;margin-top: 150px;margin-left: -322px;margin-right: -62px;
    @include media-breakpoint-down(xxxl) {
        margin-left: -220px;
    }
    @include media-breakpoint-down(md) {
        margin-left: -20px;margin-right: -20px;margin-top: 0;
    }
}

.product-detail-img{
    text-align: center;margin: 0 -62px 0 -322px;padding-bottom: 0 !important;
    @include media-breakpoint-down(xxxl) {
        margin-left: -220px;
    }
    @include media-breakpoint-down(md) {
        margin-left: -20px;margin-right: -20px;padding-top: 0 !important;overflow: hidden;
    }
    img{vertical-align: top;}
}

.product-detail-bottom{
    padding-top: 0;padding-bottom: 0 !important;
    .product-title{text-align: center;font-size: 32px;font-weight: 700;letter-spacing: -0.8px;line-height: 1;margin-bottom: 47px;margin-top: 150px;}
    .product-list{margin-right: 0;
        ul{margin-left: -12px;margin-right: -12px;}
        li{padding-left: 12px;padding-right: 12px;margin-bottom: 0;}
    }
}
@include media-breakpoint-down(xl) {
    .product-detail-bottom{
        .product-list{
            li{flex: 0 0 25%;max-width: 25%;}
        }
    }
}
@include media-breakpoint-down(md) {
    .product-detail-bottom{
        .product-title{font-size: 19px;margin-bottom: 25px;margin-top: 70px;}
        .product-list{
            margin-left: 0;
            ul{margin-left: -5px;margin-right: -5px;}
            li{padding-left: 5px;padding-right: 5px;flex: 0 0 50%;max-width: 50%;}
            .text{padding-left: 0;padding-right: 0;padding-bottom: 15px;padding-top: 15px;}
        }
    }
}