html,body{
    width: 100%; height: 100%;
}
body{position: relative;}
@include media-breakpoint-down(lg) {
    *,
    *:hover{text-decoration: none !important;}
}
@include media-breakpoint-down(md) {
    body{font-size: 13px;}
}

#skip_navi{position:fixed; left:0; top:-999px; display:block; padding:10px 20px; font-size:16px; color:#000; background:#f55454}
#skip_navi:focus{top:0; z-index:999}

#wrap{position: relative;min-height: 100%;display: flex;flex-direction: column;}

@include media-breakpoint-down(md) {
    html.open-mobile-menu{
        body{overflow: hidden;}
        .header-group{transform: translate(0);}
    }
}

.gnb{
    left: 220px;top: 41px;padding: 41px 0 41px 322px;
    .dep1{
        display: flex;transform: translateY(1px);
        > li{
            margin-right: 68px;position: relative;
            @include media-breakpoint-up(lg) {
                .icon-new-badge{position: absolute;right: -21px;top: 2px;}
            }
            > a{
                display: block;font-weight: 400;color: $dark;font-size: 18px;position: relative;text-decoration: none;line-height: 1;white-space: nowrap;
                span{position: relative;padding: 5px 0;}
                span::before{content: '';width: 0;height: 2px;position: absolute;bottom:0;left: 0;background: #111;transition: .2s .03s ease-in-out;}
                .icon-window{margin-left: 4px;transform: translateY(1px);}
                &:hover i{opacity: 1;}
            }
            &.hover{
                > a{font-weight: 700;
                    span::before{width: 100%;}
                }
                .dep2{opacity: 1;visibility: visible;}
            }
        }
        > li.active{
            > a{font-weight: 700;}
            span::before{background: $dark;width: 100%;}
        }
        .brandstory{display: none;}
    }
    .dep2{
        padding-top: 20px;padding-bottom: 10px;visibility: hidden;opacity: 0;transition: opacity .2s;position: absolute;
        > li{
            margin-bottom: 9px;white-space: nowrap;
            > a{font-size: 15px;color: #666;text-decoration: none;white-space:nowrap;
                &:hover,
                &:focus{color: #000;}
            }
        }
        > li.active{
            > a{color: $danger;font-weight: 700;}
        }
    }
}

html.gnb-hover{
    #header{height: auto;background: rgba(255,255,255,.9);transition-delay: 0s;}
}

@media (max-width: 1600px){
    .gnb{
        .dep1{
            > li{margin-right: 43px;}
        }
    }
}
@include media-breakpoint-down(xxxl) {
    .gnb{padding-left: 220px;}
}
@include media-breakpoint-down(xxl) {
    .gnb .dep1{transform: translateY(2px);}
    .gnb .dep1 > li{margin-right: 38px;}
    .gnb .dep1 > li a{font-size: 16px;}
    .gnb .dep2 > li a{font-size: 14px;}
}
@include media-breakpoint-down(xl) {
    .gnb{display: none;}
}
@include media-breakpoint-down(md) {
    .gnb{
        position: static;padding: 30px 0;
        ul{display: block;}
        li + li{margin-left: 0;margin-top: 25px;}
        a{
            font-size: 24px;color: #fff;
            span{padding: 8px 0;}
            span::before{height: 3px;}
            &:hover span::before{width: 0;}
        }
        li.active span::before{background: #fff;}
    }
}

.util{
    position: absolute;right: 62px;top: 24px;display: flex;
    a{display: flex;margin-left: 30px;width: 54px;height: 54px;align-items: center;justify-content: center;}
}
@include media-breakpoint-down(md) {
    .util{
        position: absolute;right: 0;top: 6px;display: flex;
        a{
            margin-left: 22px;width: 40px;height: 40px;justify-content: flex-end;
            .icon-search{width: 24px;height: 24px;}
            .icon-hamburger{width: 30px;height: 30px;}
        }
        [aria-label="검색"]{display: none;}
    }
}

body{padding-top: 120px;transition: padding-top .25s .05s ease-in-out;}
#header{
    min-height: 100px;max-height: 120px;position: fixed;left: 0;top: 0;width:100%;z-index: 10;overflow: hidden;
    transition: background .2s ease-in;
    .header-wrap{position: relative;margin-top: 20px;transition: margin-top .3s ease-in;}
    .logo{
        position: absolute;left: 62px;top: 41px;z-index: 1;
        a{display: block;background: url(../images/logo.svg) no-repeat 50% 50%;width: 109px;height: 18px;background-size: contain;}
    }
}

@include media-breakpoint-up(md) {
    .logo,
    .util,
    .gnb{transition: transform .2s ease-in-out;}
    html.is-scrolled.header-fixed{
        #header {
            max-height: 100px;overflow: hidden;
            .header-wrap{transform: none !important;}
        }
        .gnb .dep2{padding-bottom: 0;}
    }
    html.gnb-hover{
        #header{
            max-height: none !important;
        }
    }
}
@include media-breakpoint-down(xxxl) {
    #header{
        .logo{
            a{width: 93px;height: 17px;}
        }
    }
}
@include media-breakpoint-down(md) {
    body{padding-top: 60px;}
    #header{
        min-height: 50px;
        .logo{
            top: 20px;left: 20px;
            a{width:73px;height:12px;}
        }
        .header-wrap{margin-top: 10px;}
    }
}

html.is-scrolled{
    #header {
        //background: transparent;
        &.bg-white{background: rgba(255,255,255,0.92) !important;}
        .header-wrap{margin-top: 0;}
    }
    body:not(.body-main){
        #header{background: rgba(255,255,255,0.92);}
    }
}

html.header-fixed{
    #header{position: fixed !important;
        .header-wrap{margin-top: 0;}
    }
}

.sitemap{
    position: fixed;left: 0;top: 0;z-index: 11;width: 100%;padding-top: 160px;padding-bottom: 100px;background: #fff;box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.2);display: none;
    .layer-logo{
        position: absolute;left: 62px;top: 61px;
        a{display: block;background: url(../images/logo.svg) no-repeat 50% 50%;width: 109px;height: 18px;background-size: contain;}
    }
    .social-list{
        position: absolute;right: 144px;top: 50px;
        a{background: transparent;}
        a + a{margin-left: 5px;}
    }
    .btn-close{
        display: flex;align-items: center;justify-content: center;width: 60px;height: 60px;position: absolute;right: 43px;top: 40px;
    }
    .gnb-area{margin: 0 auto;max-width:1740px;padding-left: 62px;padding-right: 62px;}
    .all-menu{
        .dep1{
            display: flex;flex-wrap: wrap;
            > li{
                flex: 0 1 179px;margin-bottom: 55px;
                > a{font-size: 22px;font-weight: 700;}
            }
        }
        .dep2{
            padding-top: 20px;padding-bottom: 20px;
            > li{
                > a{color: #666;line-height: 1.88;font-size: 16px;}
            }
        }
    }
    .special-item{
        width: 480px;
        a{display: block;text-decoration: none;background: #f6f6f6;padding: 60px 25px;border-radius: 20px;overflow: hidden;text-align: center;}
        .img{width: 280px;height: 280px;margin: 0 auto;
            img{width: 100%;height: 100%;object-fit: cover;}
        }
        .category{color: $danger;font-size: 16px;font-weight: 700;font-family: 'Segoe UI';margin-bottom: 3px;}
        .tit{font-size: 22px;font-weight: 700;}
        .text-tag{font-size: 18px;color: #999;margin-top: 6px;}
    }
}
@include media-breakpoint-down(xxxl) {
    .sitemap .special-item{width: 398px;}
    .sitemap .special-item .img{width: 252px;height: 252px;}
}
@include media-breakpoint-down(xxl) {
    .sitemap .special-item{width: 332px;}
    .sitemap .special-item .img{width: 224px;height: 224px;}
}
@include media-breakpoint-down(xl) {
    .sitemap .special-item{width: 237px;}
    .sitemap .special-item .img{width: 168px;height: 168px;}
}
@include media-breakpoint-down(lg) {
    .sitemap{height: 100vh;}
    .sitemap .special-item{width: 398px;}
    .sitemap .special-item .img{width: 252px;height: 252px;}
    .sitemap{
        .all-menu{
            .dep1{
                display: block;
                > li{
                    margin-bottom: 25px;position: relative;
                    > a{
                        span{position: relative;}
                        span::before{content: '';width: 0;height: 2px;position: absolute;bottom:-3px;left: 0;background: #111;transition: .2s .03s ease-in-out;}
                    }
                    &.show,
                    &.active{
                        > a{
                            span::before{width: 100%;}
                        }
                        .dep2{display: block;}
                    }
                }
            }
            .dep2{
                position: absolute;left: 180px;top: 0;z-index: 1;padding: 0;display: none;
                li.active{
                    a{color: $danger;}
                }
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .sitemap{
        position: fixed;min-height: 100%;background: rgba(0,0,0,0.8);padding: 0;
        .inner{margin-left: 62px;background: #fff;position: relative;height: 100%;}
        .layer-logo{display: none;}
        .btn-close{
            left: -60px;top: 0;width: 60px;height: 100%;right: auto;padding: 27px 0 0;align-items: flex-start;
            .icon-x{width: 20px;height: 20px;background-image: url('../images/icon-x-white.svg');}
        }
        .gnb-area{
            padding-left:30px;padding-right: 15px;padding-top: 30px;height: calc(100% - 75px);overflow-x: hidden;
        }
        .all-menu{
            .dep1{
                > li{
                    margin-bottom: 32px;
                    >a {font-size: 19px;font-weight: 700;
                        span::before{display: none;}
                    }
                    &.active,
                    &.show{
                        .dep2{display: flex;}
                    }
                }
            }
            .dep2{
                position: static;flex-wrap: wrap;padding-top: 10px;margin: 0 -7px;display: flex;
                > li{
                    margin-bottom: 5px;width: 50%;padding: 0 7px;
                    > a{font-size: 14px;line-height: 1.5;}
                }
            }
        }
        .sitemap-bottom{
            background: #000;height: 75px;position: absolute;left: 0;bottom: 0;width: 100%;display: flex;justify-content: space-between;align-items: center;padding: 0 30px 0 25px;
        }
        .social-list{
            position: static;
            a{padding: 5px;}
            i{max-height: none !important;}
            i.icon-instagram-dark{background-image: url('../images/icon-instagram.svg');}
            i.icon-facebook-dark{background-image: url('../images/icon-facebook.svg');}
            i.icon-youtube-dark{background-image: url('../images/icon-youtube.svg');}
            i.icon-navershop-dark{background-image: url('../images/icon-navershop.svg');}
        }
    }
}
@media (min-width: 1500px) and (max-width: 1580px) and (orientation: landscape){
    .sitemap{
        padding-bottom: 20px;color: red;padding-top: 140px;
        .all-menu{
            .dep1 > li{margin-bottom: 15px;}
            .dep2{padding: 15px 0;}
        }
    }
}
html.open-sitemap{
    #header{max-height: none !important;overflow: visible !important;}
    .sitemap{display: block;}
    .go-video,
    .indicator{display: none;}
    @include media-breakpoint-down(lg) {
        body{overflow: hidden;}
        #wrap,
        #header-block,
        #header,
        .sitemap{height: 100%;}
        .util{display: none;}
    }
}

.search{
    position: fixed;left: 0;top: 0;z-index: 11;width: 100%;padding-top: 160px;background: #fff;box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.2);display: none;
    .layer-logo{
        position: absolute;left: 62px;top: 61px;
        a{display: block;background: url(../images/logo.svg) no-repeat 50% 50%;width: 109px;height: 18px;background-size: contain;}
    }
    .btn-close{
        display: flex;align-items: center;justify-content: center;width: 60px;height: 60px;position: absolute;right: 43px;top: 40px;
    }
    .inner{
        margin: 0 auto;max-width:556px;padding-left: 30px;padding-right: 30px;padding-bottom: 80px;
        .input-label{display: block;text-align: center;font-size: 28px;font-weight: 700;letter-spacing: -0.7px;margin-bottom: 2rem;}
        .form-control-fake{
            border-bottom: 4px solid #000;margin-bottom: 20px;position: relative;
            .btn-keyword-del{width: 30px;height: 30px;margin-left: 15px;}
            .form-control{padding: 0;border: 0;font-size: 20px;letter-spacing: -0.5px;height: auto;margin: 1rem 0;}
        }
        .tags{justify-content: center;}
        .keyword-autocomplete{
            position: absolute;left: -20px;top: 64px;right: -20px;z-index: 11;background: rgba(255, 255, 255, 0.9);
            ul{line-height: 1.75;letter-spacing: -0.4px;font-size: 16px;padding: 15px 20px;position: static !important;width: 100% !important;}
            li{color: #666;}
            a{color: #666;}
            .ui-widget.ui-widget-content{
                &.ui-autocomplete{
                    width: 100%;border: 0;
                }
                .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{border: 1px solid transparent;background: transparent;color: #000;}
            }
        }
    }
    .search-bottom{background: #f6f6f6;}
    .slider{padding: 35px 80px;max-width: 556px;margin: 0 auto;position: relative;
        .swiper-button-prev{background-image: url('../images/icon-swiper-prev.svg');background-size: 16px 20px;}
        .swiper-button-next{background-image: url('../images/icon-swiper-next.svg');background-size: 16px 20px;}
    }
    .item{
        a{display: flex;text-decoration: none;background: #f6f6f6;overflow: hidden;align-items: center;}
        .img{width: 130px;height: 130px;flex: 0 0 130px;background: #fff;display: flex;align-items: center;justify-content: center;
            img{width: 110px;height: 110px;object-fit: cover;}
        }
        .txt{padding-left: 30px;}
        .category{color: $danger;font-size: 13px;font-weight: 700;}
        .tit{font-size: 16px;font-weight: 700;}
        .text-tag{font-size: 14px;color: #999;margin-top: 5px;}
    }
}
@include media-breakpoint-down(md) {
    .search{
        padding-top: 90px;height: 100%;
        .layer-logo{display: none;}
        .btn-close{width: 50px;height: 50px;right: 0;top: 0;
            .icon-x{width: 20px;height: 20px;}
        }
        .inner{
            .input-label{font-size: 23px;}
            .form-control-fake{
                .form-control{font-size: 17px;margin: .75rem 0;}
            }
            .icon-search{width: 24px;height: 24px;}
            .keyword-autocomplete{
                top: 52px;
                a{font-size: 14px;line-height: 2.2;}
            }
        }
        .item{
            .img{width: 110px;height: 110px;flex: 0 0 110px;
                img{width: 93px;height: 93px;}
            }
            .txt{padding-left: 25px;}
            .category{font-size: 12px;}
            .tit{font-size: 15px;}
            .text-tag{font-size: 13px;}

        }
        .search-bottom{margin-top: 30px;}
        .slider{
            padding: 40px 35px 62.5px;max-width:none;width: 100%;
            .swiper-pagination{bottom: 25px;left: 0;width: 100%;;}
            .swiper-pagination-bullet{background: rgba(0,0,0,0.3);margin: 0 5px;}
            .swiper-pagination-bullet-active{background: #000;}
        }
    }
}
html.open-search{
    #header{max-height: none !important;overflow: visible !important;}
    .search{display: flex;flex-direction: column;justify-content: space-between;}
    @include media-breakpoint-down(lg) {
        body{overflow: hidden;}
        #wrap,
        #header-block,
        #header{height: 100%;}
        .go-video,
        .indicator{display: none;}
    }
}

#container-block{
    padding-bottom: 230px;flex-grow: 1;
}
@include media-breakpoint-down(md) {
    #container-block{
        padding-bottom: 150px;
    }
    .container{padding-left: 30px;padding-right: 30px;}
}

#content{padding-top: 100px;padding-left: 322px;padding-right: 62px;}
@include media-breakpoint-down(xxxl) {
    #content{padding-left: 220px;}
}
@include media-breakpoint-down(md) {
    #content{padding: 30px 20px 0;}
}

.container-custom{max-width: 1276px;}
@media (min-width: 1938px) {
    .container-custom{max-width: none;padding-right: 260px;}
}

.social{
    .list{
        ul{
            text-align: center;display: flex;
            li + li{margin-left: 20px;}
            a{display: inline-block;vertical-align: middle;width: 24px;background-position: 50% 50%;background-repeat: no-repeat;background-size: contain;margin: 0 auto;}
            i{vertical-align: middle;}
            .facebook a{background-image: url(../images/icon-facebook.svg);height: 20px;}
            .youtube a{background-image: url(../images/icon-youtube.svg);height: 17px;}
            .instagram a{background-image: url(../images/icon-instagram.svg);height: 22px;}
        }
    }
    &.active{
        .btn-sns i::before{transform: rotate(180deg);}
        .list{visibility: visible;opacity: 1;}
    }
}
@include media-breakpoint-down(md) {
    .social{
        .list{
            position: static;opacity: 1;visibility: visible;padding-top: 0;
            ul{
                display: flex;align-items: center; box-shadow: none;background: transparent;padding: 0;
                li + li{margin-left: 20px;margin-top: 0;}
                .facebook a{background-image: url(../images/sns-facebook-m.png);height: 17.5px;}
                .youtube a{background-image: url(../images/sns-youtube-m.png);height: 14.5px;}
                .instagram a{background-image: url(../images/sns-instagram-m.png);height: 18.5px;}
            }
        }
    }
}

#footer{
    background: #000;padding: 45px 220px;height: 280px;color: #fff;
    .footer-top{border-bottom: 1px solid rgba(255,255,255,0.2);margin-bottom: 25px;padding-bottom: 10px;display: flex;align-items: center;justify-content: space-between;position: relative;}
    .footer-menu{
        ul{display: flex;align-items: center;}
        li{margin-right: 39px;}
        a{color: #eee;font-size: 16px;}
        a:hover{text-decoration: underline;}
        strong{color: #fff;display: inline;}
    }
    .familysite{
        position: relative;font-family: 'Segoe UI';margin-right: -42px;
        i{width: 0;height: 0;border-style: solid;border-width: 7px 6px 0 6px;border-color: #fff transparent transparent transparent;display: inline-block;vertical-align: middle;margin-left: 5px;}
        button{font-size: 16px;font-weight: 700;display: block;width: 180px;padding: 14px 20px;text-align: left;}
        .btn-open{color: #fff;}
        .site{
            position: absolute;width: 100%;top: 45px;left: 0;z-index: 1;background: #fff;border-radius: 10px;box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);display: none;
            ul{padding: 10px 0;}
            li{
                padding: 0 20px;line-height: 1.86;
                a{
                    font-size: 14px;color: #333;position: relative;text-decoration: none;
                    &::before{content: '';width: 0;height: 1px;background: #333;transition: .3s;position: absolute;left: 0;bottom: -1px;}
                    &:hover,
                    &:focus{
                        font-weight: 700;
                        &::before{width: 100%;}
                    }
                }
            }
        }
        &.active{
            i{transform: rotate(180deg);}
            .site{display: block;}
        }
    }
    .social-list{position: absolute;right: 0;bottom: -60px;
        a{padding: 0;}
    }
    .company{font-size: 16px;font-weight: 700;margin-bottom: 2px;}
    .contact{font-size: 13px;font-weight: 700;margin-bottom: 15px;}
    address{font-size: 13px;font-weight: 300;color: rgba(255, 255, 255, 0.8);margin-bottom: 2px;}
    .copyright{font-weight: 600;font-size: 12px;color: #666;font-family: 'Segoe UI';font-size: 12px;}
}
@include media-breakpoint-down(xxxl) {
    #footer{padding-left: 171px;padding-right: 171px;}
}
@include media-breakpoint-down(xxl) {
    #footer{padding-left: 166px;padding-right: 166px;}
}
@include media-breakpoint-down(xl) {
    #footer{padding-left: 157px;padding-right: 157px;}
    #footer .footer-menu li{margin-right: 28px;}
    #footer .footer-menu a{font-size: 15px;}
}
@include media-breakpoint-down(lg) {
    #footer{padding-left: 62px;padding-right: 62px;}
}
@include media-breakpoint-down(md) {
    #footer{
        height: 318px;padding: 40px 0;
        .footer-top{display: block;padding-bottom: 15px;}
        .footer-menu{
            a{font-size: 14px;}
        }
        .familysite{
            margin-right: 0;margin-left: -20px;
            i{border-width: 6px 5px 0 5px;}
            .site{width: 170px;}
        }
        .social-list{display: none;}
        .company{font-size: 14px;}
        .contact{font-size: 12px;}
        .copyright{font-size: 10px;margin-top: 8px;}
        address{font-size: 10px;}
    }
}
@include media-breakpoint-down(sm) {
    #footer{
        height: calc(constant(safe-area-inset-bottom) + 318px);
        height: calc(env(safe-area-inset-bottom) + 318px);
        padding-bottom: calc(constant(safe-area-inset-bottom) + 40px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 40px);
        address > span{display: block;}
    }
}

.btn-top{
    position: fixed;bottom: -150px;left: 62px;z-index: 1;transition: .4s ease-in .1s;
    > a{width: 80px;height: 80px;border: 1px solid #eee;display: flex;align-items: center;justify-content: center;background: #fff;}
    html.is-scrolled.is-bottom &{
        bottom: 310px;
    }
    html.header-fixed &{
        bottom: 40px;
    }
}
@include media-breakpoint-down(xxxl) {
    .btn-top{left: 34px;}
}
@include media-breakpoint-down(md) {
    .btn-top{
        left: auto;right: 10px !important;
        > a{
            width: 40px;height: 40px;
            .icon-top{height: 14px;width: 11px;}
        }
        html.header-fixed &{
            bottom: 10px;
            bottom: calc(constant(safe-area-inset-bottom) + 10px);
            bottom: calc(env(safe-area-inset-bottom) + 10px);
        }
        html.is-scrolled.is-bottom &{
            bottom: 328px;
            bottom: calc(constant(safe-area-inset-bottom) + 328px);
            bottom: calc(env(safe-area-inset-bottom) + 328px);
        }
        html.header-fixed.show-mobile-compare &{
            bottom: 200px;
        }
        html.is-bottom.show-mobile-compare &{
            bottom: 518px;
            bottom: calc(constant(safe-area-inset-bottom) + 518px);
            bottom: calc(env(safe-area-inset-bottom) + 518px);
        }
    }
}