.body-main.story{
    #content{
        padding:0!important;
    }
}

.story-main{
    height:100vh;
    position: relative;
    .story-main-img{
        position: absolute;
        transform: scale(1);
        position:relative;
        overflow: hidden;
        height:100%;
        .swiper-container{
            width:100%;
            height:100%;
            .swiper-wrapper{
                width:100%;
                height:100%;
                .swiper-slide{
                    width:100vw;
                    height:100%;
                    background-position: center!important;
                    background-repeat: no-repeat!important;
                    background-size:cover!important;
                    &.img_01{
                        background: url(../images/story_main_01.png);
                    }
                    &.img_02{
                        background: url(../images/story_main_02.png);
                    }
                    &.img_03{
                        background: url(../images/story_main_03.png);
                    }
                }
            }
        }

    }//story-main-img
    .story-main-text{
        text-align: center;
        position: absolute;
        margin:auto;
        left:50%;
        top:30%;
        transform:translateX(-50%);
        width:957px;
        word-break: keep-all;
        .main-title{
            padding-bottom:34px;
            color:$red;
            font-size: 14px;
            font-weight: bold;
            font-family: SegoeUI;
        }
        span{
            font-size: 22px;
            line-height: 38px;
            font-weight: 300;
            display: block;
            letter-spacing: -0.7px;
        }
    }//story-main-text


    @include media-breakpoint-down(md) {

        .story-main-img{
            _transition: transform .1s;
            transition-duration: 1s;
            width:100%;
            height:100%!important;
            .swiper-container{
                height:100%;
                width:100%;
                .swiper-wrapper{
                    height: 100%;
                    .swiper-slide{
                        width:100%!important;
                        height:100%!important;
                        background-size:cover!important;
                        background-repeat: no-repeat!important;
                        &.img_01{
                            background: url(../images/story_main_01_mobile.png);
                        }
                        &.img_02{
                            background: url(../images/story_main_02_mobile.png);
                        }
                        &.img_03{
                            background: url(../images/story_main_03_mobile.png);
                        }
                    }
                }
            }

        }
        .story-main-text{
            width:100%;
            padding:0 50px;
            top:160px;
            .main-title{
                font-size:3.3vw;
                padding-bottom:24px;
            }
            span{
                font-size:2.2vh;
                line-height: 1.64;
            }
        }
    }//breakpoint [story-main]
}//story-main


.story-section{
    overflow: hidden;
    .section-container{
        max-width: 1200px;
        margin:0 auto;
        height:135vh;
        display:block;
        padding: 10vh 20px;
        // 27vh
        .img-wrap{
            width:100%;
            height:100%;
            position: relative;
            >*{
                position: absolute;
            }

            .text-wrap{
                z-index: 1;
                .h1{
                    font-size: 150px;
                    // font-size:5.8vw;
                    font-weight: lighter;
                }
                span{
                    _max-width: 560px;
                    font-size:22px;
                    color:#333333;
                    font-weight: lighter;
                    display:block;
                    word-break: keep-all;
                }
            }


        }
    }

    @include media-breakpoint-down(md){
        .section-container{
            padding:75px 20px;
            max-width: 580px;
            height:1024px;
        }
        .text-wrap{
            .h1{
                font-size: 65px!important;
            }
            span{
                font-size:14px!important;
            }
        }
    }//

    @include media-breakpoint-down(sm){
        .section-container{
            max-width: 360px;
            height:640px;
        }

    }//



    //-------------섹션 01
    &.__01{
        .section-container{
            height:160vh;
        }
        .text-wrap{
            margin-top: 260px;
            span{
                padding-left:130px;
            }
        }
        .story_01{
            left:240px;
            top:0;
        }
        .story_02{
            right:0;
            top:297px

        }
        .story_03{
            left: 30px;
            bottom:-10vh;
        }


        @media (max-width: 1100px) {
            .section-container{
                height: 120vh!important;
            }
            .text-wrap{
                margin-top: 101px;
                span{
                    padding-left:20px;
                }
            }
            .story_01{
                top:0;
                left: 28%;
                width: 130px;
            }
            .story_02{
                right: -50px;
                width: 460px;
                top: 19%;
            }
            .story_03{
                left: -30px;
                width: 280px;
                bottom: 0;
            }
        }
        @include media-breakpoint-down(md){
            .section-container{
                max-width: 580px;
                height:80vh!important;

            }
            .mobile-hidden{
                display:none;
            }
            .text-wrap{
                margin-top: 70px;
                span{
                    padding-left:20px;
                    width:240px;
                }
            }
            .story_01{
                width:120px;
            }
            .story_02{
                width: 240px;
                right: -20px;
                top: 46%;
            }
            .story_03{
                width:170px;
                bottom:-60px;
            }
        }
        @include media-breakpoint-down(sm){
            .section-container{
                height:640px!important;
                max-width: 360px;
            }
            .story_01{
                width:80px;
            }
            .story_02{
                width: 200px;
                right: -20px;
                top: 46%;
            }
            .story_03{
                width:130px;
                bottom:-60px;
            }
        }

    }//__01

    &.__02{
        .section-container{
            height: 100vh;
        }
        .text-wrap{
            margin-top:0;
            right:0;
            top:-10vh;
            text-align: right;
            span{
                padding-right: 50px;
            }
        }

        .story_01{
            left:-124px;
            top:10%;
        }
        .story_02{
            right: 0;
            top:50%;
        }

        @media (max-width: 1410px) {
            .story_01{
                left: -20px;
                width:516px;
            }
            .story_02{
                right: 0;
                width: 330px;
            }
        }
        @include media-breakpoint-down(md){
            .section-container{
                height:80vh!important;
            }
            .text-wrap{
                margin-top: 100px;
                span{
                    padding-right: 20px;
                    width: 80vw;
                }
            }
            .story_01{
                left: -20px;
                width:298px;
                top: 28%;
            }
            .story_02{
                right: 0;
                width: 205px;
            }

        }//breakpoint- [ __02 섹션 ]

        @include media-breakpoint-down(sm){
            .section-container{
                height:640px!important;
            }
            .text-wrap{
                margin-top:50px;
                span{
                    padding-right: 20px;
                    width: 80vw;
                }
            }
            .story_01{
                left: -20px;
                width:258px;
                top: 28%;
            }
            .story_02{
                right: 0;
                width: 165px;
                top: 70%;
            }

        }
    }//__02

    &.__03{
        overflow-x: hidden;
        .section-container{
            height:146vh;
        }
        .text-wrap{
            margin-top:0;
            top:-10vh;
            .h1{
                margin-left: 115px;
            }
            span{
                margin-right: 33px;
            }
        }

        .story_01{
            left: 7%;
            top: 14%;
        }
        .story_02{
            right: 0;
            top: 34%;
            z-index: -1;

        }
        .story_03{
            left:-124px;
            bottom: -10vh;
        }

        @media (max-width: 1406px) {
            .section-container{
                _height: 80vh;
                min-height: 976px;
            }
            .story_02{
                width: 300px;
            }
            .story_02{
                width: 420px;
            }
            .story_03{
              left:-20px!important;
              width:494px;
            }
        }

        @include media-breakpoint-down(md){
            .section-container{
                height: 107vh;
                margin-top: 0;
            }
            .text-wrap{
                margin-top: 75px;
                .h1{
                    margin-left: 55px;
                }
                span{
                    padding-right: 20px;
                    width: 90vw;
                }
            }
            .story_01{
                width: 150px;
                left: -20px;
                top: 14%;
            }
            .story_02{
                width:280px;
                right: -20px;
                top: 24%;
                width: 300px;
            }
            .story_03{
                width: 352px;
                left:0;
                bottom: 0;
            }
        }

        @include media-breakpoint-down(sm){
            .section-container{
                height: 70vh;
                min-height: 850px !important;
                margin-top: 0;
            }
            .text-wrap{
                margin-top: 75px;
                .h1{
                    margin-left: 55px;
                }
                span{
                    padding-right: 20px;
                    width: 90vw;
                }
            }
            .story_01{
                width: 108px;
                top:20%;
            }
            .story_02{
                width:224px;
                top: 27%;
            }
            .story_03{
                width: 270px;
            }
        }
    }//__03

    &.__04{
        padding:50px 0;
        width:100%;
        overflow: hidden;
        position: relative;
        .animation{
            position: relative;margin-top: 240px;width: 100%;overflow: hidden;height: 240px;
            .sliding{
                white-space: nowrap;line-height: 1.2;font-size: 200px;font-weight: 200;position: absolute;left: 100%;top: 0;transition: 9.5s linear;
            }
            .logo{
                text-align: center;position: absolute;width: 100%;top: 0;opacity: 0;padding-top: 62px;
                img{height: 80px;}
            }
            &.start{
                //.sliding{animation: flowing 10s linear forwards;}
                .logo{animation: fadeInOut 6s 9.8s;}
                //.logo{opacity: 1;}
                @keyframes fadeInOut {
                    30%{opacity: 1;}
                    70%{opacity: 1;}
                    100% {opacity: 0;}
                }
            }
        }
    }//__04
    @include media-breakpoint-down(md){
        &.__04{
            padding-bottom: 0;
            .animation{
                margin-top: 0;height: 125px;
                .sliding{font-size: 100px;}
                .logo{
                    height: 100px;
                    img{height: 36px;}
                }
            }
        }
    }


}//story-section
