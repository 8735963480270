.error{
    width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;
    .inner{display: flex;flex-wrap: wrap;flex-direction: column;align-items: center;text-align: center;padding: 0 20px;}
    strong{font-size: 30px;font-weight: 700;margin: 55px 0 20px;}
    p{font-size: 18px;line-height: 1.56;color: #666;margin-bottom: 80px;}
}
@include media-breakpoint-down(md) {
    .error{
        strong{font-size: 20px;margin: 30px 0 20px;}
        p{font-size: 14px;margin-bottom: 40px;word-break: keep-all;}
        .btn{min-width: 100%;}
    }
}

.terms{
    padding-top: 40px;
    & + .terms{border-top: 1px solid #ddd;padding-top: 100px;margin-top: 100px;}
    .tit{display: block;font-size: 26px;font-weight: 700;letter-spacing: -0.65px;}
    h3.tit{margin-bottom: 35px;}
    .subtit{display: block;font-size: 22px;letter-spacing: -0.55px;font-weight: 700;color: #333;}
    .tit + .subtit{margin-top: 40px;}
    .cont{font-size: 18px;line-height: 1.8;letter-spacing: -0.45px;color: #666;margin-bottom: 40px;margin-top: 15px;word-break: keep-all;}
    .table{
        margin: 15px 0;
        th,
        td{padding: 0.75rem 1rem;line-height: 1.5;}
        th{white-space: nowrap;}
        td{word-break: keep-all;}
    }
    ol{
        line-height: 1.67;margin-bottom: 40px;
        a{font-size: 18px;font-weight: 700;color: #333;}
    }
}
.old-terms{
    margin-top: 60px;
    p{text-decoration: underline;
        a{color: #666;}
    }
    .list{margin-top: 30px;
        a{color: #999;line-height: 1.8;}
    }
}
@include media-breakpoint-down(md) {
    .terms{
        padding-top: 0;
        & + .terms{padding-top: 50px;margin-top: 50px;}
        .tit{font-size: 18px;}
        h3.tit{margin-bottom: 18px;}
        .subtit{font-size: 16px;}
        .tit + .subtit{margin-top: 18px;}
        .cont{font-size: 14px;letter-spacing: -0.7px;margin-bottom: 30px;}
        .table{
            th,
            td{padding: 0.5rem .5rem;font-size: 12px;line-height: 1.5;}
        }
        ol{
            a{font-size: 15px;}
        }
    }
}

//아이리버 기존 약관 스타일
/* Useinfo */
.use_info {
    font-size: 18px;line-height: 1.8;letter-spacing: -0.45px;color: #666;
    ol {margin:20px 0 5px 0; font-weight:bold;}
    li{list-style: none;color: #666;}
    table{width: 100%;
        th,
        td{padding: 0.75rem 1rem !important;line-height: 1.5 !important;}
        th{color: #333 !important;}
        td{color: #666 !important;}
    }
    a[id]{font-size: 26px;font-weight: 700;letter-spacing: -0.65px;display: inline-block;color: #111;margin-bottom: 10px;}
}
.use_info_padd {padding-left:14px;}
.use_info_padd1 {padding-left:16px;}
.use_info_padd2 {padding-left:36px;}
.use_info_padd3 {padding-left:20px;}
.use_info_mar1 {margin-left:16px;}
.use_info_bold {font-weight:bold;}
.use_info_mar10 {margin-top:10px;}
.use_info_mar20 {margin-top:20px;}
.use_info_th {padding:2px 5px; border:1px solid #e5e5e5; background-color:#f2f2f2;}
.use_info_tr {padding:2px 5px; border:1px solid #e5e5e5;}
#UseInfoContent > b{font-size: 26px;font-weight: 700;letter-spacing: -0.65px;display: inline-block;color: #111;margin-bottom: 10px;}

@include media-breakpoint-down(md) {
    .use_info {
        font-size: 14px;
        ol {margin-bottom: 0;}
        table{
            th,
            td{padding: 0.5rem !important;line-height: 1.5 !important;font-size: 12px !important;}
        }
        a[id]{font-size: 18px;line-height: 1.5;}
    }
    #UseInfoContent > b{font-size: 18px;}

}